import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Brightness4 as Brightness4Icon } from '@mui/icons-material';

const GuestLayout = ({ children, darkMode, setDarkMode }) => {
  const navigate = useNavigate();

  const handlePricingClick = () => {
    localStorage.setItem('scrollToSection', 'pricing');
    navigate('/', { state: { scrollTo: 'pricing' } });
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            MarketRodeo
          </Typography>
          <Button color="inherit" onClick={handlePricingClick}>Pricing</Button>
          <Button color="inherit" component={Link} to="/login">Login</Button>
          {/* <Button color="inherit" component={Link} to="/signup">Sign Up</Button> */}
          <Button
            onClick={() => setDarkMode(!darkMode)}
            edge="end"
            color="inherit"
            startIcon={<Brightness4Icon />}
          >
          </Button>
        </Toolbar>
      </AppBar>
      <main>{children}</main>
    </div>
  );
};

export default GuestLayout;
