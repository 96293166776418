import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, TextField, CircularProgress } from '@mui/material';

const KrakenAllMarketData = () => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:5000'); // Connect to the WebSocket server

    socket.onopen = () => {
        console.log('Kraken WebSocket connection established');
        // Send subscription message to server
        socket.send(JSON.stringify({ action: 'subscribe', channel: 'kraken' }));
    };

    socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const { source, data: newData } = message;
        if (source === 'kraken') {
            // Handle received data
            const { pair, tickerInfo } = newData;
            const updatedData = {
              pair: pair,
              askPrice: tickerInfo.a[0],
              askVolume: parseFloat(tickerInfo.a[1]),
              askVWAP: parseFloat(tickerInfo.a[2]),
              bidPrice: tickerInfo.b[0],
              bidVolume: parseFloat(tickerInfo.b[1]),
              bidVWAP: parseFloat(tickerInfo.b[2]),
              lastPrice: tickerInfo.c[0],
              lastVolume: parseFloat(tickerInfo.v[0]),
              volumeToday: parseFloat(tickerInfo.v[1]),
              volumeLast24h: parseFloat(tickerInfo.v[2]),
              vwapToday: parseFloat(tickerInfo.p[0]),
              vwapLast24h: parseFloat(tickerInfo.p[1]),
              numTradesToday: parseInt(tickerInfo.t[0]),
              numTradesLast24h: parseInt(tickerInfo.t[1]),
              lowToday: tickerInfo.l[0],
              lowLast24h: tickerInfo.l[1],
              highToday: tickerInfo.h[0],
              highLast24h: tickerInfo.h[1],
              openToday: tickerInfo.o,
              openLast24h: tickerInfo.o
            };

            setData(prevData => {
                const index = prevData.findIndex(item => item.pair === pair);
                if (index !== -1) {
                    // If pair exists, update the corresponding entry
                    const updatedArray = [...prevData];
                    updatedArray[index] = updatedData;
                    return updatedArray;
                } else {
                    // If pair doesn't exist, add it to the array
                    return [...prevData, updatedData];
                }
            });
            setLoading(false); // Set loading state to false when data is received
        }
    };

    socket.onclose = () => {
        console.log('Kraken WebSocket connection closed');
    };

    // Cleanup function to close the socket connection when component unmounts
    return () => socket.close();
}, []);

  
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    if (data && data.length > 0) {
      return data.filter(asset =>
        asset.pair && asset.pair.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return [];
  };

  const columns = [
    { field: 'pair', headerName: 'Pair', width: 150 },
    { field: 'askPrice', headerName: 'Ask Price', width: 150 },
    { field: 'askVolume', headerName: 'Ask Volume', width: 150 },
    { field: 'askVWAP', headerName: 'Ask VWAP', width: 150 },
    { field: 'bidPrice', headerName: 'Bid Price', width: 150 },
    { field: 'bidVolume', headerName: 'Bid Volume', width: 150 },
    { field: 'bidVWAP', headerName: 'Bid VWAP', width: 150 },
    { field: 'lastPrice', headerName: 'Last Price', width: 150 },
    { field: 'lastVolume', headerName: 'Last Volume', width: 150 },
    { field: 'highLast24h', headerName: 'High Last 24h', width: 150 },
    { field: 'lowLast24h', headerName: 'Low Last 24h', width: 150 },
    { field: 'numTradesLast24h', headerName: 'Num Trades Last 24h', width: 200 },
    { field: 'numTradesToday', headerName: 'Num Trades Today', width: 200 },
    { field: 'openLast24h', headerName: 'Open Last 24h', width: 150 },
    { field: 'openToday', headerName: 'Open Today', width: 150 },
    { field: 'volumeLast24h', headerName: 'Volume Last 24h', width: 200 },
    { field: 'volumeToday', headerName: 'Volume Today', width: 150 },
    { field: 'vwapLast24h', headerName: 'VWAP Last 24h', width: 150 },
    { field: 'vwapToday', headerName: 'VWAP Today', width: 150 },
  ];
  

  return (
    <div className="table-container">
      {loading ? ( // Render spinner if loading state is true
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ margin: 'auto' }} />
          </div>
      ) : (
      <Container maxWidth="xl">
        <h1>Kraken full market data</h1>
        <TextField
          label="Search by Symbol"
          variant="outlined"
          value={searchValue}
          onChange={handleSearchChange}
          style={{ marginBottom: '20px' }}
        />
        <DataGrid rows={filterData()} 
          columns={columns}
          getRowId={(row) => row.pair}
          disableColumnMenu 
          />
      </Container>
    )}
    </div>
  );
};

export default KrakenAllMarketData;
