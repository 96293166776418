import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import DataTable from './DataTable';

const PieChartModal = ({ open, handleClose, dataArray, headline }) => {
    return (
        <Modal open={open} onClose={handleClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Box sx={{
                width: 500, // Adjust the width as needed
                maxHeight: '70vh', // Adjust the height as needed
                overflow: 'auto', // This will allow scrolling
                padding: 4,
                bgcolor: 'background.paper'
            }}>
                <Typography variant="h6" component="h2" mb={2}>
                    {headline} Details
                </Typography>
                <DataTable dataArray={dataArray} headline={headline} />
            </Box>
        </Modal>
    );
};

export default PieChartModal;
