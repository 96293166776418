import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from './Hero';
import Features from './Features';
import Pricing from './Pricing';
import Faq from './Faq';

const LandingPage = () => {
    const location = useLocation();

    useEffect(() => {
        const scrollToSection = localStorage.getItem('scrollToSection');
        
        if (scrollToSection) {
            localStorage.removeItem('scrollToSection'); // Clear the flag
            
            const { state } = location;
            if (state?.scrollTo) {
                const element = document.getElementById(state.scrollTo);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [location]);

    return (
        <div>
            <Hero /> 
            <Features />
            <div id="pricing">
                <Pricing />
            </div>
            <div id="faq">
                <Faq />
            </div>
        </div>
    );
}

export default LandingPage;
