import React, { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';
import {
    TextField,
    Button,
    Grid,
    Container,
    CircularProgress,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Box,
    TablePagination,
    Typography
} from '@mui/material';

const QuotesScreener = () => {
    const [formData, setFormData] = useState({
        priceMoreThan: '',
        priceLowerThan: '',
        changesPercentageMoreThan: '',
        changesPercentageLowerThan: '',
        changeMoreThan: '',
        changeLowerThan: '',
        dayLowMoreThan: '',
        dayLowLowerThan: '',
        dayHighMoreThan: '',
        dayHighLowerThan: '',
        yearHighMoreThan: '',
        yearHighLowerThan: '',
        marketCapMoreThan: '',
        marketCapLowerThan: '',
        priceAvg50MoreThan: '',
        priceAvg50LowerThan: '',
        priceAvg200MoreThan: '',
        priceAvg200LowerThan: '',
        volumeMoreThan: '',
        volumeLowerThan: '',
        avgVolumeMoreThan: '',
        avgVolumeLowerThan: '',
        openMoreThan: '',
        openLowerThan: '',
        previousCloseMoreThan: '',
        previousCloseLowerThan: '',
        peMoreThan: '',
        peLowerThan: '',
        sharesOutstandingMoreThan: '',
        sharesOutstandingLowerThan: '',
    });

    const [loading, setLoading] = useState(false);
    const [cryptos, setCrypto] = useState([]);
    const [, setSearchResults] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        setCrypto([]);
        setPage(0);
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('/api/filteredcryptoquotes', formData);
            const responseData = response.data.data;
            setCrypto(responseData);
            setSearchResults(responseData.length > 0);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClearFilters = () => {
        setFormData({
            priceMoreThan: '',
            priceLowerThan: '',
            changesPercentageMoreThan: '',
            changesPercentageLowerThan: '',
            changeMoreThan: '',
            changeLowerThan: '',
            dayLowMoreThan: '',
            dayLowLowerThan: '',
            dayHighMoreThan: '',
            dayHighLowerThan: '',
            yearHighMoreThan: '',
            yearHighLowerThan: '',
            marketCapMoreThan: '',
            marketCapLowerThan: '',
            priceAvg50MoreThan: '',
            priceAvg50LowerThan: '',
            priceAvg200MoreThan: '',
            priceAvg200LowerThan: '',
            volumeMoreThan: '',
            volumeLowerThan: '',
            avgVolumeMoreThan: '',
            avgVolumeLowerThan: '',
            openMoreThan: '',
            openLowerThan: '',
            previousCloseMoreThan: '',
            previousCloseLowerThan: '',
            sharesOutstandingMoreThan: '',
            sharesOutstandingLowerThan: '',
        });
        setCrypto([]);
    };

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Calculate the index of the first and last item to display on the current page
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    useEffect(() => {
        setSearchResults(cryptos.length > 0);
    }, [cryptos]);

    return (
        <div>
            <Container sx={{ mt: 4 }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: '5px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Price More Than"
                                    name="priceMoreThan"
                                    type="number"
                                    value={formData.priceMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Price Lower Than"
                                    name="priceLowerThan"
                                    type="number"
                                    value={formData.priceLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Changes % More Than"
                                    name="changesPercentageMoreThan"
                                    type="number"
                                    value={formData.changesPercentageMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Changes % Lower Than"
                                    name="changesPercentageLowerThan"
                                    type="number"
                                    value={formData.changesPercentageLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Change More Than"
                                    name="changeMoreThan"
                                    type="number"
                                    value={formData.changeMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Change Lower Than"
                                    name="changeLowerThan"
                                    type="number"
                                    value={formData.changeLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Day Low More Than"
                                    name="dayLowMoreThan"
                                    type="number"
                                    value={formData.dayLowMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Day Low Lower Than"
                                    name="dayLowLowerThan"
                                    type="number"
                                    value={formData.dayLowLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Day High More Than"
                                    name="dayHighMoreThan"
                                    type="number"
                                    value={formData.dayHighMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Day High Lower Than"
                                    name="dayHighLowerThan"
                                    type="number"
                                    value={formData.dayHighLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Year High More Than"
                                    name="yearHighMoreThan"
                                    type="number"
                                    value={formData.yearHighMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Year High Lower Than"
                                    name="yearHighLowerThan"
                                    type="number"
                                    value={formData.yearHighLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Year Low More Than"
                                    name="yearLowMoreThan"
                                    type="number"
                                    value={formData.yearLowMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Year Low Lower Than"
                                    name="yearLowLowerThan"
                                    type="number"
                                    value={formData.yearLowLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Market Cap More Than"
                                    name="marketCapMoreThan"
                                    type="number"
                                    value={formData.marketCapMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Market Cap Lower Than"
                                    name="marketCapLowerThan"
                                    type="number"
                                    value={formData.marketCapLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Price Avg 50 More Than"
                                    name="priceAvg50MoreThan"
                                    type="number"
                                    value={formData.priceAvg50MoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Price Avg 50 Lower Than"
                                    name="priceAvg50LowerThan"
                                    type="number"
                                    value={formData.priceAvg50LowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Price Avg 200 More Than"
                                    name="priceAvg200MoreThan"
                                    type="number"
                                    value={formData.priceAvg200MoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Price Avg 200 Lower Than"
                                    name="priceAvg200LowerThan"
                                    type="number"
                                    value={formData.priceAvg200LowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Volume More Than"
                                    name="volumeMoreThan"
                                    type="number"
                                    value={formData.volumeMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Volume Lower Than"
                                    name="volumeLowerThan"
                                    type="number"
                                    value={formData.volumeLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Avg Volume More Than"
                                    name="avgVolumeMoreThan"
                                    type="number"
                                    value={formData.avgVolumeMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Avg Volume Lower Than"
                                    name="avgVolumeLowerThan"
                                    type="number"
                                    value={formData.avgVolumeLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Open More Than"
                                    name="openMoreThan"
                                    type="number"
                                    value={formData.openMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Open Lower Than"
                                    name="openLowerThan"
                                    type="number"
                                    value={formData.openLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Previous Close More Than"
                                    name="previousCloseMoreThan"
                                    type="number"
                                    value={formData.previousCloseMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Previous Close Lower Than"
                                    name="previousCloseLowerThan"
                                    type="number"
                                    value={formData.previousCloseLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Shares Outstanding More Than"
                                    name="sharesOutstandingMoreThan"
                                    type="number"
                                    value={formData.sharesOutstandingMoreThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Shares Outstanding Lower Than"
                                    name="sharesOutstandingLowerThan"
                                    type="number"
                                    value={formData.sharesOutstandingLowerThan}
                                    onChange={handleChange}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="outlined" size="small" onClick={handleClearFilters}>
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button type="submit" variant="contained" color="primary" size="small">
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Container>

            {loading && (
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress />
                </Box>
            )}

            {/* Show message if no results */}
            {!loading && cryptos.length === 0 && Object.values(formData).some(value => value !== '') && (
                <Box sx={{ padding: 2, borderRadius: '5px', textAlign: 'center', marginTop: 4 }}>
                    <Typography variant="body1">No results found.</Typography>
                </Box>
            )}


            {cryptos.length > 0 && (
                <Paper>
                    <TableContainer component={Paper} sx={{ mt: 4 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Change %</TableCell>
                                    <TableCell>Change</TableCell>
                                    <TableCell>Day Low</TableCell>
                                    <TableCell>Day High</TableCell>
                                    <TableCell>Year High</TableCell>
                                    <TableCell>Year Low</TableCell>
                                    <TableCell>Market Cap</TableCell>
                                    <TableCell>Price Avg 50</TableCell>
                                    <TableCell>Price Avg 200</TableCell>
                                    <TableCell>Volume</TableCell>
                                    <TableCell>Avg Volume</TableCell>
                                    <TableCell>Open</TableCell>
                                    <TableCell>Previous Close</TableCell>
                                    <TableCell>Shares Outstanding</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cryptos.slice(startIndex, endIndex).map((crypto) => (
                                    <TableRow key={crypto.name}>
                                        <TableCell>{crypto.name}</TableCell>
                                        <TableCell>{crypto.price}</TableCell>
                                        <TableCell>{crypto.changesPercentage}</TableCell>
                                        <TableCell>{crypto.change}</TableCell>
                                        <TableCell>{crypto.dayLow}</TableCell>
                                        <TableCell>{crypto.dayHigh}</TableCell>
                                        <TableCell>{crypto.yearHigh}</TableCell>
                                        <TableCell>{crypto.yearLow}</TableCell>
                                        <TableCell>{crypto.marketCap}</TableCell>
                                        <TableCell>{crypto.priceAvg50}</TableCell>
                                        <TableCell>{crypto.priceAvg200}</TableCell>
                                        <TableCell>{crypto.volume}</TableCell>
                                        <TableCell>{crypto.avgVolume}</TableCell>
                                        <TableCell>{crypto.open}</TableCell>
                                        <TableCell>{crypto.previousClose}</TableCell>
                                        <TableCell>{crypto.sharesOutstanding}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={cryptos.length}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    />
                </Paper>
            )}
        </div>
    );
};

export default QuotesScreener;
