import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { Container, AppBar, Box, Tab, Tabs, Typography, Paper, Grid, CircularProgress, useMediaQuery } from '@mui/material';
import StockChart from './StockChart';
import CompanyProfile from './CompanyProfile';
import CompanyRating from './CompanyRating';
import CompanyIncomeStatement from './CompanyIncomeStatement';
import CompanyHistoricalDividends from './CompanyHistoricalDividends';
import { useTheme } from '@mui/material/styles';

const sections = [
  { label: 'Overview', component: <CompanyProfile /> },
  { label: 'Ratings', component: <CompanyRating /> },
  { label: 'Financials', component: <CompanyIncomeStatement /> },
  { label: 'Dividends', component: <CompanyHistoricalDividends /> },
];

const Stock = () => {
  const { symbol } = useParams(); // Extract the symbol from the URL
  const [selectedTab, setSelectedTab] = useState(0);
  const [stockData, setStockData] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    // Fetch data from the backend
    axios.get(`/api/fullquote/${symbol}`)
      .then(response => {
        setStockData(response.data.data[0]);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching stock data:', error);
        setLoading(false);
      });
  }, [symbol]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Media query for responsive design
  const isDesktop = useMediaQuery('(min-width:600px)');

  const keyStats = stockData ? {
    'Market Cap': stockData.marketCap,
    'PE': stockData.pe,
    'EPS': stockData.eps,
    'Avg Volume': stockData.avgVolume,
    'Volume': stockData.volume,
    'Day High': stockData.dayHigh,
    'Day Low': stockData.dayLow,
    'Year High': stockData.yearHigh,
    'Year Low': stockData.yearLow,
    'Price Avg 200': stockData.priceAvg200,
    'Price Avg 50': stockData.priceAvg50,
    'Previous Close': stockData.previousClose,
    'Open': stockData.open,
    'Exchange': stockData.exchange,
    'Earnings Announcement': new Date(stockData.earningsAnnouncement).toLocaleDateString(),
  } : {};

  return (
    <Container>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Container>
                  {stockData && ( // Add a conditional check here
                    <React.Fragment>
                      <Typography variant="h4">{stockData.name}</Typography>
                      <Typography variant="h5">{stockData.price}</Typography>
                      <Typography
                        variant="h6"
                        color={
                          stockData.change > 0
                            ? "green"
                            : stockData.change < 0
                              ? "red"
                              : "gray"
                        }
                      >
                        {stockData.change} ({stockData.changesPercentage}%)
                      </Typography>

                    </React.Fragment>
                  )}
                </Container>
              )}
            </Paper>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              {isDesktop && (
                <StockChart symbol={symbol} />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} sx={{ flexGrow: 1, overflowY: 'auto' }}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    Object.entries(keyStats).map(([key, value]) => (
                      <Grid item xs={6} key={key}>
                        <Typography variant="body2">{`${key}: ${value}`}</Typography>
                      </Grid>
                    ))
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs value={selectedTab} onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
                sx={{
                  "& .Mui-selected": {
                    color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                  },
                }}>
                {sections.map((section, index) => (
                  <Tab key={index} label={section.label}
                    sx={{
                      "&.Mui-selected": {
                        color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                      },
                    }}
                  />
                ))}
              </Tabs>
            </AppBar>
            <Paper elevation={3} sx={{ padding: 2 }}>
              {sections[selectedTab].component}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Stock;
