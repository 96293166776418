import { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';

function useExchanges() {
  const [exchanges, setExchanges] = useState([]);

  useEffect(() => {
    async function fetchExchanges() {
      try {
        const response = await axios.get('/api/exchanges');
        setExchanges(response.data.data.map(exchange => exchange.exchange));
      } catch (error) {
        console.error('Error fetching Exchanges:', error);
      }
    }

    fetchExchanges();
  }, []);

  return exchanges;
}

export default useExchanges;
