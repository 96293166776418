import React, { useState, useEffect, useRef } from 'react';
import axios from '../config/axiosConfig';
import { Line } from 'react-chartjs-2';
import { CircularProgress, Paper, Button, ButtonGroup, Box, Typography } from '@mui/material';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-plugin-datalabels'; // Import datalabels plugin

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, zoomPlugin);

const timeframes = ['1D', '5D', '1M', '6M', 'YTD', '1Y', '5Y', 'MAX'];

const StockChart = ({ symbol }) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState('1Y');
  const [error, setError] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);

  const chartRef = useRef(null);

  // Effect to fetch data when symbol changes
  useEffect(() => {
    setChartData(null);
    setLoading(true);
    setError(null);

    axios.get(`/api/historicaldata/${symbol}`)
      .then(response => {
        let data = response.data.data[0].historical;

        // Sort historical data by date (oldest to newest)
        data = data.sort((a, b) => new Date(a.date) - new Date(b.date));

        setHistoricalData(data);
        updateChartData(data, selectedTimeframe);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
        setError(error.message);
        setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]); // Only re-run when symbol changes

  // Effect to update chart data when timeframe changes
  useEffect(() => {
    if (historicalData.length) {
      updateChartData(historicalData, selectedTimeframe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeframe, historicalData]);

  const updateChartData = (data, timeframe) => {
    const filteredData = filterDataByTimeframe(data, timeframe);
    const labels = filteredData.map(entry => entry.label);
    const chartData = filteredData.map(entry => entry.close);

    setChartData({
      labels,
      datasets: [{
        label: 'Stock Price',
        data: chartData,
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        datalabels: {
          display: false
        }
      }],
    });
  };

  const filterDataByTimeframe = (data, timeframe) => {
    const now = new Date();
    let filteredData;
  
    switch(timeframe) {
      case '1D':
        filteredData = data.slice(-1); // last day
        break;
      case '5D':
        filteredData = data.slice(-5); // last 5 days
        break;
      case '1M':
        const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= oneMonthAgo);
        break;
      case '6M':
        const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= sixMonthsAgo);
        break;
      case 'YTD':
        const yearStart = new Date(now.getFullYear(), 0, 1);
        filteredData = data.filter(entry => new Date(entry.date) >= yearStart);
        break;
      case '1Y':
        const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= oneYearAgo);
        break;
      case '5Y':
        const fiveYearsAgo = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= fiveYearsAgo);
        break;
      case 'MAX':
      default:
        filteredData = data;
        break;
    }
  
    return filteredData;
  };

  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
    resetZoom(); // Reset zoom when timeframe changes
  };

  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error || !chartData) {
    return (
      <Typography variant="h6" color="error">
        Couldn't load chart data
      </Typography>
    );
  }

  const options = {
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    tooltips: {
      enabled: true,
    },
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
      <Line ref={chartRef} data={chartData} options={options} />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <ButtonGroup variant="contained" aria-label="timeframe button group">
          {timeframes.map((timeframe) => (
            <Button
              key={timeframe}
              onClick={() => handleTimeframeChange(timeframe)}
              sx={{
                color: selectedTimeframe === timeframe ? 'white' : 'black',
                backgroundColor: selectedTimeframe === timeframe ? 'blue' : 'grey',
                '&:hover': {
                  backgroundColor: selectedTimeframe === timeframe ? 'darkblue' : 'darkgrey',
                },
              }}
            >
              {timeframe}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    </Paper>
  );
};

export default StockChart;
