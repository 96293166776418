import React, { useState } from 'react';
import axios from '../config/axiosConfig';
import { Container, Card, CardContent, TextField, Button, Box, Grid, Typography } from '@mui/material';
import UpgradePopup from './UpgradePopup';

const AddPortfolioAsset = ({ portfolioId, handleCloseModalAndRefetchData }) => {
  const [newAsset, setNewAsset] = useState({
    symbol: '',
    quantity: 0,
    price: 0,
    currency: 'USD',
  });
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);

  const createPortfolioAsset = async (portfolioId, newAsset) => {
    try {
      const response = await axios.post('/api/createasset', { portfolioId, newAsset });
      handleCloseModalAndRefetchData();
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setShowUpgradePopup(true);
      }
      console.error('Error creating portfolio asset:', error);
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const uppercaseValue = typeof value === 'string' ? value.toUpperCase() : value;
  //   setNewAsset((prevState) => ({
  //     ...prevState,
  //     [name]: uppercaseValue,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert value to a number for numeric fields (quantity and price)
    let parsedValue = value;
    if (name === 'quantity' || name === 'price') {
      parsedValue = parseFloat(value);
      if (isNaN(parsedValue)) {
        parsedValue = 0; // Default to 0 if input is not a valid number
      }
    }

    // Apply additional checks (e.g., ensuring quantity and price are non-negative)
    if ((name === 'quantity' || name === 'price') && parsedValue <= 0) {
      // Prevent setting values lower than 0
      return;
    }

    // Update state with the sanitized and checked value
    setNewAsset((prevState) => ({
      ...prevState,
      [name]: name === 'currency' ? value.toUpperCase() : parsedValue,
    }));
  };

  // Ensure symbol is always converted to uppercase before updating state
  const handleSymbolChange = (e) => {
    const { value } = e.target;
    const uppercaseValue = value.toUpperCase();

    // Update state with uppercase symbol
    setNewAsset((prevState) => ({
      ...prevState,
      symbol: uppercaseValue,
    }));
  };

  const handleUpgradePopupClose = () => {
    setShowUpgradePopup(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createPortfolioAsset(portfolioId, newAsset);
      setNewAsset({
        symbol: '',
        quantity: 0,
        price: 0,
        currency: 'USD',
      });
    } catch (error) {
      console.error('Error adding new portfolio asset:', error);
    }
  };

  return (
    <Box className="table-container" sx={{ p: 2 }}>
      <Container maxWidth="sm">
        <Card sx={{ mx: 'auto', my: 2, p: 3, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom align="center">
              Add Transaction
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="symbol"
                    name="symbol"
                    label="Symbol"
                    variant="outlined"
                    value={newAsset.symbol}  // No need to call toUpperCase() here
                    onChange={handleSymbolChange}  // Use handleSymbolChange for symbol field
                    fullWidth
                    required
                  />

                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="quantity"
                    name="quantity"
                    label="Quantity"
                    variant="outlined"
                    type="number"
                    value={newAsset.quantity}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="price"
                    name="price"
                    label="Price per asset"
                    variant="outlined"
                    type="number"
                    value={newAsset.price}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="currency"
                    name="currency"
                    label="Currency"
                    variant="outlined"
                    value={newAsset.currency.toUpperCase()}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" type="submit">
                      Add
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        
        <UpgradePopup
          open={showUpgradePopup}
          onClose={handleUpgradePopupClose}
        />

      </Container>
    </Box>
  );
};

export default AddPortfolioAsset;
