import React from 'react';
import { Container, Box, Accordion, AccordionSummary, AccordionDetails, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const Faq = () => {
    const [expandedPanel, setExpandedPanel] = React.useState(false);
    const theme = useTheme();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpandedPanel(newExpanded ? panel : false);
    };

    const questionsAndAnswers = [
        {
            question: "What is Market Rodeo?",
            answer: "Market Rodeo provides users with stock data, market prices, analysis, basic & custom stock screeners, and cryptocurrency information to help identify investment opportunities."
        },
        {
            question: "Do you offer a free plan?",
            answer: "Yes, we offer a limited free plan that allows you to try Market Rodeo before committing to a subscription. We also offer Basic and Premium plans, which include additional features and advanced analysis tools."
        },
        {
            question: "Can I track cryptocurrency prices on Market Rodeo?",
            answer: "Yes, Market Rodeo provides cryptocurrency prices and analysis."
        },
        {
            question: "How do I subscribe to the premium plan?",
            answer: "To subscribe to the premium plan, go to the “Subscription” section and choose the plan that best suits your needs."
        },
        {
            question: "How often is the market data updated?",
            answer: "Market data is updated close to real-time, but some delays can occur to ensure accuracy and reliability."
        },
        {
            question: "How can I reach out?",
            answer: "You can reach out to us through our contact form on the website, or by emailing us at support@marketrodeo.com. We're here to help with any questions or issues you may have."
        }
    ];

    return (
        <Container sx={{ mt: 6, mb: 15 }}>
            <Typography sx={{ mt: 2 }} variant="h3" component="h2" align="center" gutterBottom>
                <strong>Questions? We got answers.</strong>
            </Typography>

            <Box sx={{ padding: theme.spacing(4) }}>
                {questionsAndAnswers.map((qa, index) => (
                    <Accordion key={index} expanded={expandedPanel === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={expandedPanel === `panel${index}` ? <CloseIcon /> : <ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                            sx={{ minHeight: 80 }} // Adjust the minHeight value as needed
                        >
                            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                {qa.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h6">
                                {qa.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>);
}

export default Faq;
