import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, TextField, CircularProgress } from '@mui/material';

const AllTopCryptosData = () => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true); // State to track loading state

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:5000'); // Connect to the WebSocket server

    socket.onopen = () => {
      console.log('TopCryoptos WebSocket connection established');
      socket.send(JSON.stringify({ action: 'subscribe', channel: 'topCryptos' }));
    };

    socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const { source, data: newData } = message;
        
        if (source === 'topCryptos') {
            setData(prevData => {
                // Check if newData already exists in the data array
                const index = prevData.findIndex(item => item.symbol === newData.symbol && item.exchange === newData.exchange);
    
                if (index !== -1) {
                    // If newData exists, update the corresponding entry
                    return prevData.map((item, i) => {
                        if (i === index) {
                            return { ...item, ...newData }; // Merge newData with existing item
                        }
                        return item;
                    });
                } else {
                    // If newData doesn't exist, add it to the array
                    return [...prevData, newData];
                }
            });
            setLoading(false); // Set loading state to false when data is received
        }
    };
    
    
    

    socket.onclose = () => {
      console.log('Coinbase WebSocket connection closed');
    };

    // Cleanup function to close the socket connection when component unmounts
    return () => socket.close();
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    if (data && data.length > 0) {
      return data.filter(asset =>
         asset.symbol && asset.symbol.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return [];
  };

  const columns = [
    { field: 'symbol', headerName: 'Symbol', width: 150 },
    { field: 'price', headerName: 'Best Ask', width: 150 },
    { field: 'bestBid', headerName: 'Best Bid', width: 150 },
    { field: 'exchange', headerName: 'Exchange', width: 150 },
  ];
  

  return (
    <div className="table-container">
      {loading ? ( // Render spinner if loading state is true
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </div>
      ) : (
        <Container maxWidth="sm">
          <h1>Top Market Data</h1>
          <TextField
            label="Search by Symbol"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ marginBottom: '20px' }}
          />
          <DataGrid
            rows={(filterData())}
            columns={columns}
            getRowId={(row) => `${row.symbol}-${row.exchange}`} 
            disableColumnMenu
          />
        </Container>
      )}
    </div>
  );
};

export default AllTopCryptosData;
