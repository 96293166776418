import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import ProfileDetails from './ProfileDetails';
import AccountSettings from './AccountSettings';
import NotificationSettings from './NotificationSettings';
import { useAuth } from './AuthContext';

const ProfilePage = () => {
    const { user } = useAuth();

    // Check if user has a googleId to determine authentication method
    const isGoogleUser = Boolean(user.googleId);

    return (
        <Container>
            <Typography variant="h4" gutterBottom sx={{ mt: 3, mb: 3 }}>User Profile</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} style={{ padding: '16px' }}>
                        <ProfileDetails />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    {isGoogleUser ? (
                        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                            <NotificationSettings />
                        </Paper>
                    ) : (
                        <div>
                            <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
                                <AccountSettings />
                            </Paper>
                            <Paper elevation={3} style={{ padding: '16px' }}>
                                <NotificationSettings />
                            </Paper>
                        </div>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProfilePage;
