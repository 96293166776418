// src/components/withPlanAccess.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const withPlanAccess = (WrappedComponent, allowedPlans) => {
  const ComponentWithAccessControl = (props) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    console.log("User object: ", user);
    console.log("User plan: ", user ? user.plan : "No user");

    // Check if the user's plan is in the allowed plans
    if (!user || !allowedPlans.includes(user.plan.name)) {
      // Redirect to upgrade page or show an appropriate message
      return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h3>Access Denied</h3>
          <p>Your current plan does not allow access to this feature.</p>
          <button onClick={() => navigate('/upgrade')}>Upgrade Plan</button>
        </div>
      );
    }

    // Render the wrapped component if access is allowed
    return <WrappedComponent {...props} />;
  };

  return ComponentWithAccessControl;
};

export default withPlanAccess;
