import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch, Typography, Grid, Button, Snackbar, Typography as MuiTypography } from '@mui/material';
import axios from '../config/axiosConfig';

const NotificationSettings = () => {
  const [notifications, setNotifications] = useState({
    email: false,
    sms: false,
    push: false
  });

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    // Fetch user notification preferences on component mount
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('/api/user/profile');
        setNotifications(response.data.data.notificationPreferences);
      } catch (error) {
        console.error('Error fetching notification preferences:', error);
      }
    };

    fetchNotifications();
  }, []);

  const handleChange = (e) => {
    setNotifications({ ...notifications, [e.target.name]: e.target.checked });
  };

  const handleSave = async () => {
    try {
      await axios.put('/api/user/notifications', notifications);
      setSnackbarMessage('Notification preferences updated successfully');
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error updating notification preferences:', error);
      setSnackbarMessage('Failed to update notification preferences');
      setShowSnackbar(true);
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>Notification Settings</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={notifications.email}
                onChange={handleChange}
                name="email"
                color="primary"
              />
            }
            label="Email Notifications"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={notifications.sms}
                onChange={handleChange}
                name="sms"
                color="primary"
                disabled
              />
            }
            label={
              <div>
                SMS Notifications <MuiTypography variant="body2" color="textSecondary">(coming soon)</MuiTypography>
              </div>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={notifications.push}
                onChange={handleChange}
                name="push"
                color="primary"
                disabled
              />
            }
            label={
              <div>
                Push Notifications <MuiTypography variant="body2" color="textSecondary">(coming soon)</MuiTypography>
              </div>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>
      
      {/* Snackbar Component */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
};

export default NotificationSettings;
