import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, TextField, CircularProgress } from '@mui/material';

const CoinbaseAllMarketData = () => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true); // State to track loading state

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:5000'); // Connect to the WebSocket server

    socket.onopen = () => {
      console.log('Coinbase WebSocket connection established');
      socket.send(JSON.stringify({ action: 'subscribe', channel: 'coinbase' }));
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const { source, data: newData } = message;
    
      if (source === 'coinbase') {
        setData(prevData => {
          // Check if newData already exists in the data array
          const index = prevData.findIndex(item => item.product_id === newData.product_id);
    
          if (index !== -1) {
            // If newData exists, update the corresponding entry
            return [
              ...prevData.slice(0, index),
              newData,
              ...prevData.slice(index + 1)
            ];
          } else {
            // If newData doesn't exist, add it to the array
            return [...prevData, newData];
          }
        });
        setLoading(false); // Set loading state to false when data is received
      }
    };
    

    socket.onclose = () => {
      console.log('Coinbase WebSocket connection closed');
    };

    // Cleanup function to close the socket connection when component unmounts
    return () => socket.close();
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    if (data && data.length > 0) {
      return data.filter(asset =>
         asset.product_id && asset.product_id.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return [];
  };

  const columns = [
    { field: 'product_id', headerName: 'Product', width: 150 },
    { field: 'price', headerName: 'Price', width: 150 },
    { field: 'open_24h', headerName: 'Open (24h)', width: 150 },
    { field: 'volume_24h', headerName: 'Volume (24h)', width: 150 },
    { field: 'low_24h', headerName: 'Low (24h)', width: 150 },
    { field: 'high_24h', headerName: 'High (24h)', width: 150 },
    { field: 'volume_30d', headerName: 'Volume (30d)', width: 150 },
    { field: 'best_bid', headerName: 'Best Bid', width: 150 },
    { field: 'best_bid_size', headerName: 'Best Bid Size', width: 150 },
    { field: 'best_ask', headerName: 'Best Ask', width: 150 },
    { field: 'best_ask_size', headerName: 'Best Ask Size', width: 150 },
    { field: 'last_size', headerName: 'Last Size', width: 150 },
  ];
  

  return (
    <div className="table-container">
      {loading ? ( // Render spinner if loading state is true
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </div>
      ) : (
        <Container maxWidth="xl">
          <h1>Coinbase Market Data</h1>
          <TextField
            label="Search by Symbol"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ marginBottom: '20px' }}
          />
          <DataGrid
            rows={(filterData())}
            columns={columns}
            getRowId={(row) => row.product_id}
            disableColumnMenu
          />
        </Container>
      )}
    </div>
  );
};

export default CoinbaseAllMarketData;
