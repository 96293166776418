import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Container } from '@mui/material';
import TitleAndInfo from './TitleAndInfo';

const MarketLosers = () => {
    const [marketLosers, setMarketLosers] = useState([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const baseUrl = window.location.origin;

    const title = "Market Biggest Losers: Identify Top Declining Stocks of the Day";
    const info = "The `Market Biggest Losers` screener provides a list of stocks that have experienced the largest percentage losses in value within a single trading day. This valuable tool helps investors identify underperforming stocks and potential trading opportunities. Stay informed about market downturns, make strategic decisions, and take advantage of significant price drops with the Market Biggest Losers screener. Ideal for traders seeking to understand market dips and explore investment opportunities during downturns.";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/stocks/marketlosers');

                if (response.data && response.data.data) {
                    // Set market Losers data
                    setMarketLosers(response.data.data);
                    setLoading(false);
                } else {
                    console.error('Invalid response data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Columns configuration
    const columns = [
        {
            field: 'symbol',
            headerName: 'Symbol',
            width: 150,
            renderCell: (params) => (
              <div>
                <RouterLink
                  to={`${baseUrl}/Stock/${params.row.symbol}`}
                  style={{ color: theme.palette.text.primary, textDecoration: 'none' }}
                >
                  {params.row.symbol}
                </RouterLink>
              </div>
            ),
          },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'change', headerName: 'Change', flex: 1, minWidth: 150 },
        { field: 'price', headerName: 'Price', flex: 1, minWidth: 150 },
        { field: 'changesPercentage', headerName: 'Changes Percentage', flex: 1, minWidth: 150 },
    ];

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="table-container">
            <Container maxWidth="xl">
            <TitleAndInfo title={title} info={info} />
                <DataGrid
                    rows={marketLosers}
                    columns={columns}
                    getRowId={(row) => row.symbol}
                    disableColumnMenu
                    pagination
                    paginationPosition="middle"
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </Container>
        </div>
    );
};

export default MarketLosers;
