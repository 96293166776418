import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    Terms and Conditions
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Last Revised: 07/08/2024
                </Typography>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        1. Welcome
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Welcome to Market Rodeo! We appreciate your choice to use our services.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        2. Service Overview
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Market Rodeo provides users with stock data, market prices, analysis, custom stock screeners, and cryptocurrency information to help identify investment opportunities. You acknowledge that you are exclusively accountable for all the investment decisions you make using Market Rodeo.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        3. Adherence to Terms
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        By engaging with our service, you consent to be bound by these Terms and Conditions. Should you disagree with these terms, we kindly ask that you refrain from using our services.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        4. Payments and Subscription
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        All financial transactions for Market Rodeo services are managed through [Your Payment Processor]. Your use of our service implies your agreement to comply with the terms and conditions set forth by [Your Payment Processor].
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        5. Ownership of Intellectual Assets
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    Unless explicitly specified, the Service constitutes our proprietary asset, including all source code, databases, functions, software, website aesthetics, audio, video, text, photographs, and graphics (collectively referred to as the “Content”). Additionally, the trademarks, service marks, and logos within the Service (the “Marks”) are either owned, controlled by us, or licensed to us. These assets are protected by copyright, trademark laws, and other intellectual property rights, both domestically and internationally.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        6. Responsibilities of Users
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Users are responsible for their own investment decisions. Market Rodeo does not provide financial advice. Users must ensure that their use of the services complies with applicable laws and regulations. Market Rodeo does not vouch for the authenticity, precision, or reliability of any information provided by users nor does it endorse any opinions expressed by users. You concur that any reliance on materials provided by other users is at your sole discretion.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        7. Liability Limitation
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        To the fullest extent permissible by prevailing law, Market Rodeo along with its affiliates, agents, directors, employees, suppliers, or licensors shall not be held accountable for any direct, indirect, punitive, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, usage, data, or other intangible losses, resulting from the utilization of, or incapability to use, this service.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        8. Account Termination
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        We reserve the right to terminate or suspend your account forthwith, sans prior notification or liability, for any reason whatsoever, inclusive but not limited to a breach of the Terms. Upon termination, your privilege to use the Service will be revoked immediately.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        9. Jurisdiction
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        These Terms and Conditions are governed by applicable laws. Any claims arising from or related to your use of our site will be handled exclusively by the appropriate courts. However, we reserve the right to take legal action against you for breaches of these conditions in your country of residence or any other relevant jurisdiction.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        10. Changes to Terms
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Market Rodeo reserves the right to modify these Terms and Conditions at any time. Users will be notified of any changes, and continued use of the services constitutes acceptance of the new terms.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        11. Contact Information
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Should you have any inquiries regarding these Terms and Conditions, feel free to get in touch with us at support@marketrodeo.com.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default TermsAndConditions;
