import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, TextField, CircularProgress } from '@mui/material';
import axios from '../config/axiosConfig';

const ByBitAllMarketData = () => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true); // State to track loading state

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/allbybitdata');
      console.log('Response:', response);
      const jsonData = await response.json();
      console.log('JSON Data:', jsonData);
      if (jsonData && jsonData.data) {
        setData(jsonData.data);
      } else {
        console.error('Error: Invalid data format received');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false after data fetch completes (whether successful or not)
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when component mounts

    const interval = setInterval(() => {
      fetchData(); // Fetch data every 15 seconds
    }, 15000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    if (data && data.length > 0) {
      return data.filter(asset =>
        asset.s && asset.s.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return [];
  };

  const columns = [
    //{ field: 't', headerName: 'Timestamp', width: 150 },
    { field: 's', headerName: 'Symbol', width: 150 },
    { field: 'lp', headerName: 'Last Price', width: 150 },
    { field: 'bp', headerName: 'Bid Price', width: 150 },
    { field: 'ap', headerName: 'Ask Price', width: 150 },
    { field: 'o', headerName: 'Opening Price', width: 150 },
    { field: 'h', headerName: 'Highest Price', width: 150 },
    { field: 'l', headerName: 'Lowest Price', width: 150 },
    { field: 'v', headerName: 'Volume Traded', width: 150 },
    { field: 'qv', headerName: 'Quote Volume', width: 150 },
    //{ field: 'last_size', headerName: 'Last Size', width: 150 },
].filter(Boolean);


  return (
    <div className="table-container">
      {loading ? ( // Render spinner if loading state is true
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ margin: 'auto' }} />
          </div>
      ) : (
        <Container maxWidth="xl">
          <h1>ByBit Market Data</h1>
          <TextField
            label="Search by Symbol"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ marginBottom: '20px' }}
          />
          <DataGrid rows={filterData()} columns={columns} 
          getRowId={(row) => row.s}
          disableColumnMenu
          />
        </Container>
      )}
    </div>
  );
};

export default ByBitAllMarketData;
