import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, Box, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';

const exchanges = ['binance', 'coinbase', 'kraken'];

const cryptos = [
  { name: 'Bitcoin', symbol: 'BTCUSD' },
  { name: 'Ethereum', symbol: 'ETHUSD' },
  { name: 'Solana', symbol: 'SOLUSD' },
  { name: 'Binance Coin', symbol: 'BNBUSD' },
  { name: 'Cardano', symbol: 'ADAUSD' }
];

// Styled components for custom styles
const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const StyledPaper = styled(Paper)({
    padding: '16px',
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#333',
    marginBottom: '16px',
    height: '180px', // Set a fixed height for consistent alignment
  });
  

const StyledHeader = styled(Typography)({
  marginBottom: '20px',
});

const CryptoPriceTracker = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const socket = new WebSocket('ws://localhost:5000');
  
      socket.onopen = () => {
        console.log('TopCryptos WebSocket connection established');
        socket.send(JSON.stringify({ action: 'subscribe', channel: 'topCryptos' }));
      };
  
      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const { source, data: newData } = message;
  
        if (source === 'topCryptos') {
          const normalizedData = normalizeData(newData);
          setData(prevData => {
            const index = prevData.findIndex(item => item.symbol === normalizedData.symbol && item.exchange === normalizedData.exchange);
  
            if (index !== -1) {
              return prevData.map((item, i) => (i === index ? { ...item, ...normalizedData } : item));
            } else {
              return [...prevData, normalizedData];
            }
          });
          setLoading(false);
        }
      };
  
      socket.onclose = () => {
        console.log('TopCryptos WebSocket connection closed');
      };
  
      return () => socket.close();
    }, []);
  
    const normalizeData = (data) => {
      let normalized = {};
  
      switch (data.exchange) {
        case 'coinbase':
          normalized = {
            symbol: data.symbol,
            price: parseFloat(data.price),
            bestBid: parseFloat(data.bestBid),
            exchange: data.exchange
          };
          break;
        case 'kraken':
          normalized = {
            symbol: data.symbol,
            price: parseFloat(data.tickerInfo.c[0]),
            bestBid: parseFloat(data.tickerInfo.c[0]),
            exchange: data.exchange
          };
          break;
        case 'binance':
          normalized = {
            symbol: data.symbol,
            price: parseFloat(data.c),
            bestBid: parseFloat(data.b),
            exchange: data.exchange
          };
          break;
        default:
          break;
      }
  
      return normalized;
    };
  
    const getBestPrices = (symbol) => {
      const filteredData = data.filter(item => item.symbol === symbol);
      const bestAsk = filteredData.reduce((prev, current) => (prev.price < current.price ? prev : current), { price: Infinity });
      const bestBid = filteredData.reduce((prev, current) => (prev.bestBid > current.bestBid ? prev : current), { bestBid: -Infinity });
  
      return { bestAsk, bestBid };
    };
  
    const renderTable = () => {
        const bestPrices = cryptos.reduce((acc, crypto) => {
          acc[crypto.symbol] = getBestPrices(crypto.symbol);
          return acc;
        }, {});
      
        return (
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <StyledPaper>
                <Typography variant="h6">Exchange</Typography>
                {exchanges.map((exchange) => (
                  <Typography key={exchange} variant="body1">{exchange}</Typography>
                ))}
              </StyledPaper>
            </Grid>
            {cryptos.map((crypto) => (
              <Grid item xs={2} key={crypto.symbol}>
                <StyledPaper>
                  <Typography variant="h6">{crypto.name}</Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {exchanges.map((exchange) => {
                      const exchangeData = data.find(item => item.symbol === crypto.symbol && item.exchange === exchange);
                      const { bestAsk, bestBid } = bestPrices[crypto.symbol];
                      const price = exchangeData ? exchangeData.price.toFixed(2) : '-';
                      const bid = exchangeData ? exchangeData.bestBid.toFixed(2) : '-';
                      const isBestPrice = exchangeData && exchangeData.price === bestAsk.price;
                      const isBestBid = exchangeData && exchangeData.bestBid === bestBid.bestBid;
      
                      return (
                        <div key={exchange} style={{ textAlign: 'center', color: isBestPrice ? 'green' : 'inherit' }}>
                          <Typography variant="body2">{price}</Typography>
                          <Typography variant="body2" style={{ color: isBestBid ? 'red' : 'inherit' }}>{bid}</Typography>
                        </div>
                      );
                    })}
                  </div>
                </StyledPaper>
              </Grid>
            ))}
          </Grid>
        );
      };

    return (
      <Container maxWidth="md">
        {loading ? (
          <StyledBox>
            <CircularProgress />
          </StyledBox>
        ) : (
          <div>
            <StyledHeader variant="h4">Top Market Data</StyledHeader>
            {renderTable()}
          </div>
        )}
      </Container>
    );
  };
  
  export default CryptoPriceTracker;
  