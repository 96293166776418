import axios from 'axios';

// Define the baseURL using the environment variable or a fallback value
const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
    baseURL: backendURL,
    withCredentials: true,
});

export default axiosInstance;
