import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Divider, CircularProgress, Box } from '@mui/material';

import { useAuth } from './components/AuthContext';
import { lightTheme, darkTheme } from './styles/theme';

import AuthenticatedLayout from './components/AuthenticatedLayout';
import GuestLayout from './components/GuestLayout';

import LandingPage from './components/LandingPage';
import ProfilePage from './components/ProfilePage';
import Dashboard from './components/Dashboard';
import Watchlist from './components/Watchlist';
import Portfolio from './components/Portfolio';
import OptionsCalculator from './components/OptionsCalculator';
import BlackScholesCalculator from './components/BlackScholesCalculator';
import StockReturnCalculator from './components/StockReturnCalculator';
import DailyAggregated from './components/DailyAggregated';
import CryptoAllMarketData from './components/CryptoAllMarketData';
import CoinbaseAllMarketData from './components/CoinbaseAllMarketData';
import BitvavoAllMarketData from './components/BitvavoAllMarketData';
import KrakenAllMarketData from './components/KrakenAllMarketData';
import ByBitAllMarketData from './components/ByBitAllMarketData';
import CryptoArbitrageScanner from './components/CryptoArbitrageScanner';
import AllTopCryptosData from './components/AllTopCryptosData';
import OptionChain from './components/OptionChain';
import StockCustomScreener from './components/StockCustomScreener';
import QuotesScreener from './components/QuotesScreener';
import AvgScreener from './components/AvgScreener';
import MarketGainers from './components/MarketGainers';
import MarketLosers from './components/MarketLosers';
import MarketActives from './components/MarketActives';
import Stock from './components/Stock';
import CryptoQuoteScreener from './components/CryptoQuoteScreener';
import CryptoPriceTracker from './components/CryptoPriceTracker';
//import Signup from './components/SignUp';
import EmailVerification from './components/EmailVerification';
import Login from './components/Login';
import NotFound from './components/NotFound';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Faq from './components/Faq';
import Footer from './components/Footer';

const AppRouter = () => {
  const { isAuthenticated, user, loading } = useAuth();
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('darkMode');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  if (loading) {
    return <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    </div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {isAuthenticated ? (
          <AuthenticatedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/watchlist" element={<Watchlist user={user} />} />
              <Route path="/portfolio" element={<Portfolio user={user} />} />
              <Route path="/optionscalculator" element={<OptionsCalculator />} />
              <Route path="/blackscholescalculator" element={<BlackScholesCalculator />} />
              <Route path="/stockreturncalculator" element={<StockReturnCalculator />} />
              <Route path="/dailyaggregated" element={<DailyAggregated />} />
              <Route path="/cryptoallmarketdata" element={<CryptoAllMarketData />} />
              <Route path="/coinbaseallmarketdata" element={<CoinbaseAllMarketData />} />
              <Route path="/bitvavoallmarketdata" element={<BitvavoAllMarketData />} />
              <Route path="/krakenallmarketdata" element={<KrakenAllMarketData />} />
              <Route path="/bybitallmarketdata" element={<ByBitAllMarketData />} />
              <Route path="/cryptoarbitragescanner" element={<CryptoArbitrageScanner />} />
              <Route path="/alltopcryptosdata" element={<AllTopCryptosData />} />
              <Route path="/optionchain" element={<OptionChain />} />
              <Route path="/stockcustomscreener" element={<StockCustomScreener />} />
              <Route path="/cryptoquotescreener" element={<CryptoQuoteScreener />} />
              <Route path="/AvgScreener" element={<AvgScreener />} />
              <Route path="/cryptopricetracker" element={<CryptoPriceTracker />} />
              <Route path="/quotesscreener" element={<QuotesScreener />} />
              <Route path="/marketgainers" element={<MarketGainers />} />
              <Route path="/marketlosers" element={<MarketLosers />} />
              <Route path="/marketactives" element={<MarketActives />} />
              <Route path="/stock/:symbol" element={<Stock />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/email-verification" element={<EmailVerification />} />
              <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            </Routes>
          </AuthenticatedLayout>
        ) : (
          <GuestLayout darkMode={darkMode} setDarkMode={setDarkMode}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              {/* <Route path="/signup" element={<Signup />} />*/}
              <Route path="/email-verification" element={<EmailVerification />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/FAQ" element={<Faq />} />
            </Routes>
          </GuestLayout>
        )}

        <Divider style={{ width: '100%', marginTop: 20 }} />
        <Footer isAuthenticated={isAuthenticated} />
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;