import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useCountries from '../hooks/useCountries';
import useExchanges from '../hooks/useExchanges';
import useIndustries from '../hooks/useIndustries';
import useSectors from '../hooks/useSectors';
import TitleAndInfo from './TitleAndInfo';

import axios from '../config/axiosConfig';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Container,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  TablePagination
} from '@mui/material';

const baseUrl = window.location.origin;
const title = "Stock Screener: Find Stocks that Meet Your Investment Criteria";
const info = "Discover stocks that align with your investment strategy using our Stock Screener. This powerful tool allows you to search for stocks based on various criteria such as market cap, price, volume, beta, sector, and country. Tailor your search to find the perfect investment opportunities, make informed decisions, and enhance your portfolio with the Stock Screener. Ideal for investors looking to customize their stock selection process and achieve their financial goals.";

const StockCustomScreener = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    marketCapMoreThan: '',
    marketCapLowerThan: '',
    priceMoreThan: '',
    priceLowerThan: '',
    betaMoreThan: '',
    betaLowerThan: '',
    volumeMoreThan: '',
    volumeLowerThan: '',
    dividendMoreThan: '',
    dividendLowerThan: '',
    isEtf: false,
    isFund: false,
    isActivelyTrading: false,
    sector: '',
    industry: '',
    country: '',
    exchange: '',
  });

  const countries = useCountries();
  const exchanges = useExchanges();
  const industries = useIndustries();
  const sectors = useSectors();
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    setStocks([]);
    setPage(0);
    e.preventDefault();
    setLoading(true);

    // Filter out boolean properties with false value
    const filteredData = Object.entries(formData).reduce((acc, [key, value]) => {
      if (typeof value === 'boolean' && !value) return acc;
      acc[key] = value;
      return acc;
    }, {});

    try {
      const response = await axios.post('/api/stocks/customscreener', filteredData);
      console.log(response.data);
      setStocks(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleClearFilters = () => {
    setFormData({
      marketCapMoreThan: '',
      marketCapLowerThan: '',
      priceMoreThan: '',
      priceLowerThan: '',
      betaMoreThan: '',
      betaLowerThan: '',
      volumeMoreThan: '',
      volumeLowerThan: '',
      dividendMoreThan: '',
      dividendLowerThan: '',
      isEtf: false,
      isFund: false,
      isActivelyTrading: false,
      sector: '',
      industry: '',
      country: '',
      exchange: '',
    });
    setStocks([]);
  };

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Calculate the index of the first and last item to display on the current page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <div>
      <Container sx={{ mt: 4 }}>
      <TitleAndInfo title={title} info={info} />
        <form onSubmit={handleSubmit}>
          <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: '5px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Market Cap More Than"
                  name="marketCapMoreThan"
                  type="number"
                  value={formData.marketCapMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Market Cap Lower Than"
                  name="marketCapLowerThan"
                  type="number"
                  value={formData.marketCapLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Price More Than"
                  name="priceMoreThan"
                  type="number"
                  value={formData.priceMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Price Lower Than"
                  name="priceLowerThan"
                  type="number"
                  value={formData.priceLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Beta More Than"
                  name="betaMoreThan"
                  type="number"
                  value={formData.betaMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Beta Lower Than"
                  name="betaLowerThan"
                  type="number"
                  value={formData.betaLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Volume More Than"
                  name="volumeMoreThan"
                  type="number"
                  value={formData.volumeMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Volume Lower Than"
                  name="volumeLowerThan"
                  type="number"
                  value={formData.volumeLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Dividend More Than"
                  name="dividendMoreThan"
                  type="number"
                  value={formData.dividendMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Dividend Lower Than"
                  name="dividendLowerThan"
                  type="number"
                  value={formData.dividendLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Sector</InputLabel>
                  <Select
                    name="sector"
                    value={formData.sector}
                    onChange={handleChange}
                  >
                    {sectors.map((sector) => (
                      <MenuItem key={sector} value={sector}>{sector}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Industry</InputLabel>
                  <Select
                    name="industry"
                    value={formData.industry}
                    onChange={handleChange}
                  >
                    {industries.map((industry) => (
                      <MenuItem key={industry} value={industry}>{industry}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country} value={country}>{country}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Exchange</InputLabel>
                  <Select
                    name="exchange"
                    value={formData.exchange}
                    onChange={handleChange}
                  >
                    {exchanges.map((exchange) => (
                      <MenuItem key={exchange} value={exchange}>{exchange}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isEtf}
                      onChange={handleChange}
                      name="isEtf"
                    />
                  }
                  label="Is ETF"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isFund}
                      onChange={handleChange}
                      name="isFund"
                    />
                  }
                  label="Is Fund"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isActivelyTrading}
                      onChange={handleChange}
                      name="isActivelyTrading"
                    />
                  }
                  label="Is Actively Trading"
                />
              </Grid>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button variant="outlined" size="small" onClick={handleClearFilters}>
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary" size="small">
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>

      {loading && (
        <Box sx={{ padding: 2, borderRadius: '5px' }}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      )}

      {stocks.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Symbol</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Market Cap</TableCell>
                <TableCell>Sector</TableCell>
                <TableCell>Industry</TableCell>
                <TableCell>Beta</TableCell>
                <TableCell>Last Annual Dividend</TableCell>
                <TableCell>Volume</TableCell>
                <TableCell>Exchange</TableCell>
                <TableCell>Exchange Short Name</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Is ETF</TableCell>
                <TableCell>Is Fund</TableCell>
                <TableCell>Is Actively Trading</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Map through the data based on the current page and rows per page */}
              {stocks.slice(startIndex, endIndex).map((stock, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <RouterLink to={`${baseUrl}/Stock/${stock.symbol}`} style={{ color: theme.palette.primary.main }}>
                      {stock.symbol}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{stock.companyName}</TableCell>
                  <TableCell>{stock.price}</TableCell>
                  <TableCell>{stock.marketCap}</TableCell>
                  <TableCell>{stock.sector}</TableCell>
                  <TableCell>{stock.industry}</TableCell>
                  <TableCell>{stock.beta}</TableCell>
                  <TableCell>{stock.lastAnnualDividend}</TableCell>
                  <TableCell>{stock.volume}</TableCell>
                  <TableCell>{stock.exchange}</TableCell>
                  <TableCell>{stock.exchangeShortName}</TableCell>
                  <TableCell>{stock.country}</TableCell>
                  <TableCell>{stock.isEtf ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{stock.isFund ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{stock.isActivelyTrading ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
            component="div"
            count={stocks.length} // Total number of items
            rowsPerPage={rowsPerPage} // Number of items per page
            page={page} // Current page
            onPageChange={(event, newPage) => setPage(newPage)} // Handle page change
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0); // Reset page to 0 when rows per page changes
            }}
          />
        </TableContainer>
      )}

    </div>
  );
};

export default StockCustomScreener;
