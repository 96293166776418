import React, { useState } from 'react';
import { Container, Grid, Typography, Box, useTheme, Switch, FormControlLabel } from '@mui/material';
import PricingPlan from './PricingPlan';

const pricingPlans = [
  {
    monthlyPrice: '$0/month',
    yearlyPrice: '$0/month',
    title: 'Free',
    description: 'Basic features with limited access',
    features: ['1 Portfolio', '1 Watchlist', 'Pre-made screens', 'Basic filters', 'Limited data', 'Limited Access', '10 results limit', 'Delayed data'],
    btn: 'Get Started',
  },
  {
    monthlyPrice: '$29/month',
    yearlyPrice: '$19/month',
    title: 'Basic',
    description: 'Extended features with more access',
    features: ['4 Portfolio', '4 Watchlist', 'Pre-made screens', 'Basic filters', 'Company data', 'Limited Access', '100 results limit', 'Custom Screens'],
    btn: 'Subscribe Now',
  },
  {
    monthlyPrice: '$39/month',
    yearlyPrice: '$29/month',
    title: 'Premium',
    description: 'All features with full access',
    features: ['10 Portfolio', '10 Watchlist', 'Pre-made screens', 'Basic filters', ' Advanced filters', 'Advanced company data', 'Custom Screens', 'Prioritized feature requests'],
    btn: 'Join Now',
  },
];

const Pricing = () => {
  const theme = useTheme();
  const [isMonthly, setIsMonthly] = useState(false);

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <Container sx={{ mt: 6, mb: 15 }}>
      <Box sx={{ padding: theme.spacing(4) }}>
        <Typography sx={{ mt: 2, mb: 10 }} variant="h4" component="h2" align="center" gutterBottom>
          <strong>Our Plans</strong>
        </Typography>
        <FormControlLabel
          control={<Switch checked={isMonthly} onChange={handleToggle} />}
          label={isMonthly ? 'Monthly' : 'Annually'}
          sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}
        />
        <Grid container spacing={4}>
          {pricingPlans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: theme.spacing(4),
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                <PricingPlan
                  price={isMonthly ? plan.monthlyPrice : plan.yearlyPrice}
                  title={plan.title}
                  description={plan.description}
                  features={plan.features}
                  btn={plan.btn}
                  isMonthly={isMonthly}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Pricing;
