import React from 'react';
import { AuthProvider } from './components/AuthContext';
import AppRouter from './AppRouter';

const App = () => {
  return (
    <AuthProvider>
    <AppRouter />
    </AuthProvider>
  );
};

export default App;
