import React, { useState } from 'react';
import { Container, Card, CardContent, FormControl, InputLabel, Button, Select, MenuItem, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TextField } from '@mui/material';
import { fv } from 'financial';

const StockReturnCalculator = () => {
  const [startingAmount, setStartingAmount] = useState('');
  const [additionalContribution, setAdditionalContribution] = useState('');
  const [contributionFrequency, setContributionFrequency] = useState('Monthly');
  const [returnRate, setReturnRate] = useState('');
  const [yearsOfGrowth, setYearsOfGrowth] = useState('');
  const [totalReturn, setTotalReturn] = useState(null);
  const [growthData, setGrowthData] = useState([]);

  const handleCalculate = () => {
    let periodsPerYear = 12; // Default to monthly

    if (contributionFrequency === 'Yearly') {
      periodsPerYear = 1;
    } else if (contributionFrequency === 'Weekly') {
      periodsPerYear = 52;
    }

    const interestRatePerPeriod = returnRate / 100 / periodsPerYear;
    const totalPeriods = yearsOfGrowth * periodsPerYear;
    const monthlyContribution = contributionFrequency === 'Monthly' ? additionalContribution : additionalContribution / periodsPerYear;

    let currentAmount = parseFloat(startingAmount);
    let currentYearlyContribution = parseFloat(additionalContribution) * periodsPerYear;
    let currentTotalContributions = 0;
    let currentTotalInterestEarned = 0;

    const newData = [];

    for (let i = 0; i < yearsOfGrowth; i++) {
      const interestEarned = currentAmount * interestRatePerPeriod;
      currentAmount += interestEarned + currentYearlyContribution;
      currentTotalContributions += currentYearlyContribution;
      currentTotalInterestEarned += interestEarned;

      newData.push({
        year: new Date().getFullYear() + i,
        startingAmount: i === 0 ? startingAmount : newData[i - 1].endBalance,
        annualContribution: currentYearlyContribution.toFixed(2),
        totalContributions: currentTotalContributions.toFixed(2),
        interestEarned: interestEarned.toFixed(2),
        totalInterestEarned: currentTotalInterestEarned.toFixed(2),
        endBalance: currentAmount.toFixed(2),
      });
    }

    setGrowthData(newData);
    
    const futureValue = fv(interestRatePerPeriod, totalPeriods, -monthlyContribution, -startingAmount, 1);
    setTotalReturn(futureValue.toFixed(2));
  };

  return (
    <div className="table-container">
      <Container maxWidth="xl">
        <Card sx={{ maxWidth: '500px', mx: 'auto', my: 2 }}>
          <CardContent>
            <div sx={{ p: 2 }}>
              <h2>Stock Return Calculator</h2>
              <TextField
                fullWidth
                id="startingAmount"
                label="Starting Amount"
                type="number"
                value={startingAmount}
                onChange={(e) => setStartingAmount(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="additionalContribution"
                label="Additional Contribution"
                type="number"
                value={additionalContribution}
                onChange={(e) => setAdditionalContribution(e.target.value)}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth >
              <InputLabel id="optionTypeLabel"> </InputLabel>
                <Select
                  labelId="contributionFrequencyLabel"
                  id="contributionFrequency"
                  value={contributionFrequency}
                  sx={{ mb: 2 }}
                  onChange={(e) => setContributionFrequency(e.target.value)}
                >
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                id="returnRate"
                label="Return Rate (%)"
                type="number"
                value={returnRate}
                onChange={(e) => setReturnRate(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                id="yearsOfGrowth"
                label="Years of Growth"
                type="number"
                value={yearsOfGrowth}
                onChange={(e) => setYearsOfGrowth(e.target.value)}
                sx={{ mb: 2 }}
              />
              <div className="text-center" style={{ textAlign: 'center' }}>
              <Button variant="contained" color="primary" onClick={handleCalculate} sx={{ mx: 'auto' }}>
                Calculate
              </Button>
              
              {totalReturn && (
                <div className="mt-3">
                  <h4>Total Return: ${totalReturn}</h4>
                </div>
              )}
            </div>
            </div>
          </CardContent>
        </Card>

        {growthData.length > 0 && (
          <TableContainer sx={{ mt: 4 }}>
            <Table striped bordered hover>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Starting Amount</TableCell>
                  <TableCell>Annual Contribution</TableCell>
                  <TableCell>Total Contributions</TableCell>
                  <TableCell>Interest Earned</TableCell>
                  <TableCell>Total Interest Earned</TableCell>
                  <TableCell>End Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {growthData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.year}</TableCell>
                    <TableCell>${data.startingAmount}</TableCell>
                    <TableCell>${data.annualContribution}</TableCell>
                    <TableCell>${data.totalContributions}</TableCell>
                    <TableCell>${data.interestEarned}</TableCell>
                    <TableCell>${data.totalInterestEarned}</TableCell>
                    <TableCell>${data.endBalance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </div>
  );
};

export default StockReturnCalculator;
