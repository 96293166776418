import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, TextField, CircularProgress } from '@mui/material';

const BitvavoAllMarketData = () => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const socket = new WebSocket('ws://localhost:5000'); // Connect to the WebSocket server

    socket.onopen = () => {
      console.log('Bitvavo WebSocket connection established');
      socket.send(JSON.stringify({ action: 'subscribe', channel: 'bitvavo' }));
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const { source, data: newData } = message;
    
      if (source === 'bitvavo') {
        setData(prevData => {
          // Check if newData already exists in the data array
          const index = prevData.findIndex(item => item.market === newData.market);
    
          if (index !== -1) {
            // If newData exists, update the corresponding entry
            return [
              ...prevData.slice(0, index),
              newData,
              ...prevData.slice(index + 1)
            ];
          } else {
            // If newData doesn't exist, add it to the array
            return [...prevData, newData];
          }
        });
        setLoading(false); // Set loading state to false when data is received
      }
    };
    

    socket.onclose = () => {
      console.log('Bitvavo WebSocket connection closed');
    };

    // Cleanup function to close the socket connection when component unmounts
    return () => socket.close();
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    if (data && data.length > 0) {
      return data.filter(asset =>
        asset.market && asset.market.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return [];
  };
  

  const columns = [
    { field: 'market', headerName: 'Market', width: 150 },
    /*:{ field: 'timestamp', headerName: 'Timestamp', width: 180 },*/
    { field: 'last', headerName: 'Last', width: 150 },
    { field: 'open', headerName: 'Open', width: 150 },
    { field: 'high', headerName: 'High', width: 150 },
    { field: 'low', headerName: 'Low', width: 150 },
    { field: 'bid', headerName: 'Bid', width: 150 },
    { field: 'bidSize', headerName: 'Bid Size', width: 150 },
    { field: 'ask', headerName: 'Ask', width: 150 },
    { field: 'askSize', headerName: 'Ask Size', width: 150 },
    { field: 'volume', headerName: 'Volume', width: 150 },
    { field: 'volumeQuote', headerName: 'Volume Quote', width: 200 },
  ];
  
  
  

  return (
    <div>
    {loading ? ( // Render spinner if loading state is true
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ margin: 'auto' }} />
          </div>
      ) : (
        <Container maxWidth="xl">
          <h1>Bitvavo full market data</h1>
          <TextField
            label="Search by Symbol"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ marginBottom: '20px' }}
          />
          <DataGrid rows={filterData()} columns={columns} 
          getRowId={(row) => row.market}
          disableColumnMenu
          />
        </Container>
      )}
    </div>
  );
};

export default BitvavoAllMarketData;
