import React, { useState, useEffect } from 'react';
import TitleAndInfo from './TitleAndInfo';
import { DataGrid } from '@mui/x-data-grid';
import { Container, TextField, CircularProgress } from '@mui/material';

const CryptoAllMarketData = () => {
  const [data, setData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true); // State to track loading state

  const title = "Binance full market data";
  const info = `Our ticker updates provide real-time information on symbols that have changed within the past 24 hours. Symbols with recent activity are included, ensuring you stay informed about the latest market developments.`;

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:5000'); // Connect to the WebSocket server

    socket.onopen = () => {
      console.log('Binance WebSocket connection established');
      // Send subscription message to server
      socket.send(JSON.stringify({ action: 'subscribe', channel: 'binance' }));
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const { source, data: newData } = message;
      if (source === 'binance') {
        // Handle received data
        setData(prevData => {
          const updatedData = { ...prevData };
          if (Array.isArray(newData)) {
            newData.forEach(asset => {
              updatedData[asset.s] = asset;
            });
          } else {
            // Handle single object
            updatedData[newData.s] = newData;
          }
          return updatedData;
        });
        setLoading(false); // Set loading state to false when data is received
      }
    };


    socket.onclose = () => {
      console.log('Binance WebSocket connection closed');
    };

    // Cleanup function to close the socket connection when component unmounts
    return () => socket.close();
  }, []);



  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterData = () => {
    if (searchValue) {
      // If there's a search value, filter the data based on the symbol
      return Object.values(data).filter((asset) =>
        asset.s.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      // If there's no search value, return the entire data set
      return Object.values(data);
    }
  };

  const columns = [
    { field: 's', headerName: 'Symbol', width: 150 },
    { field: 'p', headerName: 'Price Change', width: 150 },
    { field: 'P', headerName: '% Price Change', width: 180 },
    { field: 'w', headerName: 'Weighted Avg. Price', width: 200 },
    { field: 'c', headerName: 'Last Price', width: 200 },
    { field: 'Q', headerName: 'Last Quantity', width: 200 },
    { field: 'b', headerName: 'Best Bid Price', width: 150 },
    { field: 'B', headerName: 'Bid Quantity', width: 150 },
    { field: 'a', headerName: 'Best Ask Price', width: 150 },
    { field: 'A', headerName: 'Ask Quantity', width: 150 },
    { field: 'o', headerName: 'Open Price', width: 150 },
    { field: 'h', headerName: 'High Price', width: 150 },
    { field: 'l', headerName: 'Low Price', width: 150 },
    { field: 'v', headerName: 'Total Traded Base Asset Volume', width: 250 },
    { field: 'q', headerName: 'Total Traded Quote Asset Volume', width: 250 },
    { field: 'O', headerName: 'Statistics Open Time', width: 200 },
    { field: 'C', headerName: 'Statistics Close Time', width: 200 },
    { field: 'F', headerName: 'First Trade ID', width: 150 },
    { field: 'L', headerName: 'Last Trade ID', width: 150 },
    { field: 'n', headerName: 'Total Number of Trades', width: 200 },
  ];

  return (
    <div>
      {loading ? ( // Render spinner if loading state is true
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </div>
      ) : (
        <Container maxWidth="xl">
          <TitleAndInfo title={title} info={info} />
          <TextField
            label="Search by Symbol"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ marginBottom: '20px' }}
          />
          <DataGrid
            rows={filterData()}
            columns={columns}
            disableColumnMenu
            getRowId={(row) => row.s} // Update getRowId to use the 's' field for the symbol
          />
        </Container>
      )}
    </div>
  );
};

export default CryptoAllMarketData;
