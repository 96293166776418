import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../config/axiosConfig'; // Adjust the path as necessary
import { TextField, InputAdornment, IconButton, Paper, List, ListItem, ListItemText } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    const searchRef = useRef(null);

    const handleSearch = async (event) => {
        event.preventDefault();
        if (!query) return;

        try {
            const response = await axios.get(`/api/search`, {
                params: { query }
            });
            setResults(response.data);
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handleResultClick = (symbol) => {
        navigate(`/stock/${symbol}`);
        setQuery('');
        setResults([]);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setResults([]);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={searchRef} style={{ position: 'relative', width: '200px' }}>
            <form onSubmit={handleSearch}>
                <TextField
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

            </form>
            {results.length > 0 && (
                <Paper style={{ position: 'absolute', top: '40px', width: '100%', zIndex: 1 }}>
                    <List>
                        {results.map((result) => (
                            <ListItem button key={result._id} onClick={() => handleResultClick(result.symbol)}>
                                <ListItemText primary={`${result.symbol} - ${result.name}`} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </div>
    );
};

export default SearchBar;
