import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import axios from '../config/axiosConfig';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Container,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  TablePagination,
  Typography
} from '@mui/material';
import useExchanges from '../hooks/useExchanges';
import TitleAndInfo from './TitleAndInfo';

const AvgScreener = () => {
  const exchanges = useExchanges();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    averageType: '50',
    comparisonType: 'below',
    percentage: '5',
    priceMoreThan: '',
    priceLowerThan: '',
    marketCapMoreThan: '',
    marketCapLowerThan: '',
    exchange: ''
  });
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const baseUrl = window.location.origin;

  const title = "Average Price Stock Screener";
  const info = "Compare current stock prices to their 50-day or 200-day moving averages. Filter stocks based on how their current price compares to these averages, along with additional criteria like price range, market cap, and exchange.";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStocks([]);
    setPage(0);

    try {
      const response = await axios.post('/api/avgscreener', formData);
      setStocks(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <Container sx={{ mt: 4 }}>
      <TitleAndInfo title={title} info={info} />
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: '5px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Average Type</InputLabel>
                <Select
                  name="averageType"
                  value={formData.averageType}
                  onChange={handleChange}
                  label="Average Type"
                >
                  <MenuItem value="50">50-Day Average</MenuItem>
                  <MenuItem value="200">200-Day Average</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Comparison</InputLabel>
                <Select
                  name="comparisonType"
                  value={formData.comparisonType}
                  onChange={handleChange}
                  label="Comparison"
                >
                  <MenuItem value="below">Below Average</MenuItem>
                  <MenuItem value="above">Above Average</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Percentage</InputLabel>
                <Select
                  name="percentage"
                  value={formData.percentage}
                  onChange={handleChange}
                  label="Percentage"
                >
                  <MenuItem value="5">5%</MenuItem>
                  <MenuItem value="10">10%</MenuItem>
                  <MenuItem value="20">20%</MenuItem>
                  <MenuItem value="30">30%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Price More Than"
                name="priceMoreThan"
                type="number"
                value={formData.priceMoreThan}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Price Lower Than"
                name="priceLowerThan"
                type="number"
                value={formData.priceLowerThan}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Market Cap More Than"
                name="marketCapMoreThan"
                type="number"
                value={formData.marketCapMoreThan}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Market Cap Lower Than"
                name="marketCapLowerThan"
                type="number"
                value={formData.marketCapLowerThan}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Exchange</InputLabel>
                <Select
                  name="exchange"
                  value={formData.exchange}
                  onChange={handleChange}
                  label="Exchange"
                >
                  <MenuItem value="">All Exchanges</MenuItem>
                  {exchanges.map((exchange) => (
                    <MenuItem key={exchange} value={exchange}>
                      {exchange}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Search
          </Button>
        </Box>
      </form>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {!loading && stocks.length === 0 && (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography>No results found.</Typography>
        </Box>
      )}

      {stocks.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Symbol</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>50-Day Avg</TableCell>
                <TableCell>200-Day Avg</TableCell>
                <TableCell>Market Cap</TableCell>
                <TableCell>Exchange</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stocks.slice(startIndex, endIndex).map((stock) => (
                <TableRow key={stock.symbol}>
                  <TableCell>
                    <RouterLink to={`${baseUrl}/Stock/${stock.symbol}`} style={{ color: theme.palette.primary.main }}>
                      {stock.symbol}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{stock.name}</TableCell>
                  <TableCell>{stock.price}</TableCell>
                  <TableCell>{stock.priceAvg50}</TableCell>
                  <TableCell>{stock.priceAvg200}</TableCell>
                  <TableCell>{stock.marketCap}</TableCell>
                  <TableCell>{stock.exchange}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={stocks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </TableContainer>
      )}
    </Container>
  );
};

export default AvgScreener;