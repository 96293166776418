import React, { useState } from 'react';
import {
  Modal,
  FormControl,
  InputLabel,
  Input,
  Button,
  Box,
} from '@mui/material';

const CreateModal = ({ open, onClose, onCreate, title }) => {
  const [itemName, setItemName] = useState('');

  const handleCreate = () => {
    // Call the onCreate callback with the item name
    onCreate(itemName);
    // Clear the input field and close the modal
    setItemName('');
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 8,
        boxShadow: 4,
        textAlign: 'center'
      }}>
        <h2>{title}</h2>
        <FormControl fullWidth>
          <InputLabel>Enter Name</InputLabel>
          <Input
            type="text"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary">
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateModal;
