import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { Container, Box, Grid, CircularProgress, Typography } from '@mui/material';

const CompanyProfile = () => {
    const { symbol } = useParams();
    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const response = await axios.get(`/api/companyprofile/${symbol}`);
                setCompanyData(response.data.data[0]);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchCompanyData();
    }, [symbol]);

    if (loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <Typography variant="h6" color="error">
                        Error fetching data: {error.message}
                    </Typography>
                </Box>
            </Container>
        );
    }

    if (!companyData) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <Typography variant="h6" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Box my={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Company Information</Typography>
                        <Typography variant="subtitle1"><strong>Symbol:</strong> {companyData.symbol}</Typography>
                        <Typography variant="subtitle1"><strong>CEO:</strong> {companyData.ceo}</Typography>
                        <Typography variant="subtitle1"><strong>Industry:</strong> {companyData.industry}</Typography>
                        <Typography variant="subtitle1"><strong>Sector:</strong> {companyData.sector}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Financial Information</Typography>
                        <Typography variant="subtitle1">
                            <strong>Market Cap:</strong> ${companyData.mktCap ? companyData.mktCap.toLocaleString() : 'N/A'}
                        </Typography>
                        <Typography variant="subtitle1"><strong>Beta:</strong> {companyData.beta}</Typography>
                        <Typography variant="subtitle1"><strong>Range:</strong> {companyData.range}</Typography>
                        <Typography variant="subtitle1">
                            <strong>Volume Average:</strong> {companyData.volAvg ? companyData.volAvg.toLocaleString() : 'N/A'}
                        </Typography>
                        <Typography variant="subtitle1"><strong>DCFDiff:</strong> {companyData.dcfDiff}</Typography>
                        <Typography variant="subtitle1"><strong>DCF:</strong> {companyData.dcf}</Typography>
                        <Typography variant="subtitle1"><strong>IPO Date:</strong> {new Date(companyData.ipoDate).toDateString()}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Contact Information</Typography>
                        <Typography variant="subtitle1"><strong>Address:</strong> {companyData.address}, {companyData.city}, {companyData.state}, {companyData.zip}</Typography>
                        <Typography variant="subtitle1"><strong>Country:</strong> {companyData.country}</Typography>
                        <Typography variant="subtitle1"><strong>Phone:</strong> {companyData.phone}</Typography>
                        <Typography variant="subtitle1"><strong>Website:</strong> <a href={companyData.website} target="_blank" rel="noopener noreferrer">{companyData.website}</a></Typography>
                    </Grid>

                </Grid>
            </Box>
        </Container>
    );
};

export default CompanyProfile;
