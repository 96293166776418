import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import axios from '../config/axiosConfig';

const OptionChain = () => {
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the stock symbol from the URL
        const url = new URL(window.location.href);
        const stockSymbol = url.searchParams.get("symbol"); 
  
        // Make the request using the dynamic stock symbol
        const response = await axios.get(`/api/optionchain/${stockSymbol}`);
        console.log('response.data:', response.data);
        
        if (response.data && response.data.data && response.data.data.length > 0) {
          setData(response.data.data[0]);
          setLoading(false);
        } else {
          setError('No data found');
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  
  useEffect(() => {
    if (data && data.options) {
      const uniqueDates = [
        ...new Set(
          data.options.map((option) =>
            new Date(option.expiration * 1000).toLocaleDateString()
          )
        ),
      ];
      setSelectedDate(uniqueDates[0]);
    }
  }, [data]);

  useEffect(() => {
    if (data && selectedDate) {
      const optionsForDate = data.options.filter(
        (option) =>
          new Date(option.expiration * 1000).toLocaleDateString() === selectedDate
      );
      setFilteredOptions(optionsForDate);
    }
  }, [data, selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  if (loading) return 
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
    </Box>;
  if (error) return <Typography>Error loading data: {error.message}</Typography>;
  if (!data) return <Typography>No data available</Typography>;

  const uniqueDates = data.options
    ? [
        ...new Set(
          data.options.map((option) =>
            new Date(option.expiration * 1000).toLocaleDateString()
          )
        ),
      ]
    : [];

  const calls = filteredOptions.filter((option) => option.side === 'call');
  const puts = filteredOptions.filter((option) => option.side === 'put');

  return (
    <div className="table-container">
          <Container maxWidth="xl">
    
    <Typography variant="h4" gutterBottom mt="4">
      {data.underlyingAsset}
    </Typography>
      <Select
        value={selectedDate}
        onChange={handleDateChange}
        displayEmpty
        fullWidth
      >
        {uniqueDates.map((date) => (
          <MenuItem key={date} value={date}>
            {date}
          </MenuItem>
        ))}
      </Select>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
            <TableRow>
                <TableCell align="center">Bid</TableCell>
                <TableCell align="center">Ask</TableCell>
                <TableCell align="center">Last</TableCell>
                <TableCell align="center">Option Symbol</TableCell>

                <TableCell align="center">Strike</TableCell>

                <TableCell align="center">Option Symbol</TableCell>
                <TableCell align="center">Last</TableCell>
                <TableCell align="center">Ask</TableCell>
                <TableCell align="center">Bid</TableCell>

          </TableRow>
            </TableHead>
            <TableBody>
                {calls.map((callOption) => {
                    const putOption = puts.find((put) => put.strike === callOption.strike);
                    return (
                    <TableRow key={callOption._id}>
                        {/* Fields for call options */}
                        <TableCell align="center">{callOption.bid}</TableCell>
                        <TableCell align="center">{callOption.ask}</TableCell>
                        <TableCell align="center">{callOption.last}</TableCell>
                        <TableCell align="center">{callOption.optionSymbol}</TableCell>
                        
                        <TableCell align="center">{callOption.strike}</TableCell>

                        {/* Fields for put options */}

                        <TableCell align="center">{putOption ? putOption.optionSymbol : '-'}</TableCell>
                        <TableCell align="center">{putOption ? putOption.last : '-'}</TableCell>
                        <TableCell align="center">{putOption ? putOption.ask : '-'}</TableCell>
                        <TableCell align="center">{putOption ? putOption.bid : '-'}</TableCell>

                    </TableRow>
                    );
                })}
                </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
    </div>
  );
};

export default OptionChain;
