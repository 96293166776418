import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { useAuth } from './AuthContext';
import { Container, Box, Grid, CircularProgress, Typography } from '@mui/material';
import BarChart from './BarChart';
import ContentAccessControl from './ContentAccessControl';

const CompanyIncomeStatement = () => {
    const { user } = useAuth();
    const { symbol } = useParams();
    const [incomeStatementData, setIncomeStatementData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchIncomeStatementData = async () => {
            try {
                const response = await axios.get(`/api/companyincomestatement/${symbol}`);
                // Check if response.data.data is not null before sorting
                if (response.data.data) {
                    // Sort the income statement data by date in ascending order (oldest first)
                    const sortedData = response.data.data.sort((a, b) => new Date(a.date) - new Date(b.date));
                    setIncomeStatementData(sortedData);
                } else {
                    console.log('No data to sort');
                }
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };
    
        fetchIncomeStatementData();
    }, [symbol]);
    

    if (loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <Typography variant="h6" color="error">
                        Error fetching data: {error.message}
                    </Typography>
                </Box>
            </Container>
        );
    }

    if (!incomeStatementData || incomeStatementData.length === 0 || !Array.isArray(incomeStatementData)) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <Typography variant="h6" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    // Prepare data for chart (sorted in ascending order for oldest to newest)
    const chartDataRevenue = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Revenue',
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)',
            data: incomeStatementData.map(statement => statement.revenue)
        }]
    };

    const chartDataGrossProfit = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Gross Profit',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)',
            data: incomeStatementData.map(statement => statement.grossProfit)
        }]
    };

    const chartDataEPS = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'EPS',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)',
            data: incomeStatementData.map(statement => statement.eps)
        }]
    };

    const chartDataEPSDiluted = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'EPS Diluted',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
            hoverBorderColor: 'rgba(153, 102, 255, 1)',
            data: incomeStatementData.map(statement => statement.epsdiluted)
        }]
    };

    const chartDataEBITDA = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'EBITDA',
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
            hoverBorderColor: 'rgba(255, 159, 64, 1)',
            data: incomeStatementData.map(statement => statement.ebitda)
        }]
    };

    const chartDataEBITDARatio = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'EBITDA Ratio',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
            hoverBorderColor: 'rgba(75, 192, 192, 1)',
            data: incomeStatementData.map(statement => statement.ebitdaratio)
        }]
    };

    const chartDataGrossProfitRatio = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Gross Profit Ratio',
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 206, 86, 0.4)',
            hoverBorderColor: 'rgba(255, 206, 86, 1)',
            data: incomeStatementData.map(statement => statement.grossProfitRatio)
        }]
    };

    const chartDataIncomeBeforeTax = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Income Before Tax',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)',
            data: incomeStatementData.map(statement => statement.incomeBeforeTax)
        }]
    };

    const chartDataIncomeBeforeTaxRatio = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Income Before Tax Ratio',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)',
            data: incomeStatementData.map(statement => statement.incomeBeforeTaxRatio)
        }]
    };

    const chartDataIncomeTaxExpense = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Income Tax Expense',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
            hoverBorderColor: 'rgba(75, 192, 192, 1)',
            data: incomeStatementData.map(statement => statement.incomeTaxExpense)
        }]
    };

    const chartDataInterestExpense = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Interest Expense',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
            hoverBorderColor: 'rgba(153, 102, 255, 1)',
            data: incomeStatementData.map(statement => statement.interestExpense)
        }]
    };

    const chartDataInterestIncome = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Interest Income',
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
            hoverBorderColor: 'rgba(255, 159, 64, 1)',
            data: incomeStatementData.map(statement => statement.interestIncome)
        }]
    };

    const chartDataNetIncome = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Net Income',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
            hoverBorderColor: 'rgba(153, 102, 255, 1)',
            data: incomeStatementData.map(statement => statement.netIncome)
        }]
    };

    const chartDataNetIncomeRatio = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Net Income Ratio',
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
            hoverBorderColor: 'rgba(255, 159, 64, 1)',
            data: incomeStatementData.map(statement => statement.netIncomeRatio)
        }]
    };

    const chartDataResearchAndDevelopmentExpenses = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Research and Development Expenses',
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 206, 86, 0.4)',
            hoverBorderColor: 'rgba(255, 206, 86, 1)',
            data: incomeStatementData.map(statement => statement.researchAndDevelopmentExpenses)
        }]
    };

    const chartDataSellingAndMarketingExpenses = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Selling and Marketing Expenses',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
            hoverBorderColor: 'rgba(75, 192, 192, 1)',
            data: incomeStatementData.map(statement => statement.sellingAndMarketingExpenses)
        }]
    };

    const chartDataWeightedAverageShsOut = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Weighted Average Shares Outstanding',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)',
            data: incomeStatementData.map(statement => statement.weightedAverageShsOut)
        }]
    };

    const chartDataWeightedAverageShsOutDil = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Weighted Average Shares Outstanding (Diluted)',
            backgroundColor: 'rgba(104, 132, 245, 0.2)',
            borderColor: 'rgba(104, 132, 245, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(104, 132, 245, 0.4)',
            hoverBorderColor: 'rgba(104, 132, 245, 1)',
            data: incomeStatementData.map(statement => statement.weightedAverageShsOutDil)
        }]
    };

    const chartDataGeneralAndAdministrativeExpenses = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'General and Administrative Expenses',
            backgroundColor: 'rgba(75, 192, 75, 0.2)',
            borderColor: 'rgba(75, 192, 75, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75, 192, 75, 0.4)',
            hoverBorderColor: 'rgba(75, 192, 75, 1)',
            data: incomeStatementData.map(statement => statement.generalAndAdministrativeExpenses)
        }]
    };

    const chartDataOperatingExpenses = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Operating Expenses',
            backgroundColor: 'rgba(245, 66, 139, 0.2)',
            borderColor: 'rgba(245, 66, 139, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(245, 66, 139, 0.4)',
            hoverBorderColor: 'rgba(245, 66, 139, 1)',
            data: incomeStatementData.map(statement => statement.operatingExpenses)
        }]
    };

    const chartDataOtherExpenses = {
        labels: incomeStatementData.map(statement => new Date(statement.date).toLocaleDateString()),
        datasets: [{
            label: 'Other Expenses',
            backgroundColor: 'rgba(240, 128, 128, 0.2)',
            borderColor: 'rgba(240, 128, 128, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(240, 128, 128, 0.4)',
            hoverBorderColor: 'rgba(240, 128, 128, 1)',
            data: incomeStatementData.map(statement => statement.otherExpenses)
        }]
    };



    return (
        <Container>
            <Box my={4}>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic' || user.plan.name === 'Free'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Revenue</Typography>
                            <BarChart data={chartDataRevenue} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Gross Profit</Typography>
                            <BarChart data={chartDataGrossProfit} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>EPS</Typography>
                            <BarChart data={chartDataEPS} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>EPS Diluted</Typography>
                            <BarChart data={chartDataEPSDiluted} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>EBITDA</Typography>
                            <BarChart data={chartDataEBITDA} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>EBITDA Ratio</Typography>
                            <BarChart data={chartDataEBITDARatio} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Gross Profit Ratio</Typography>
                            <BarChart data={chartDataGrossProfitRatio} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Income Before Tax</Typography>
                            <BarChart data={chartDataIncomeBeforeTax} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Income Before Tax Ratio</Typography>
                            <BarChart data={chartDataIncomeBeforeTaxRatio} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Income Tax Expense</Typography>
                            <BarChart data={chartDataIncomeTaxExpense} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Interest Expense</Typography>
                            <BarChart data={chartDataInterestExpense} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Interest Income</Typography>
                            <BarChart data={chartDataInterestIncome} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Net Income</Typography>
                            <BarChart data={chartDataNetIncome} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Net Income Ratio</Typography>
                            <BarChart data={chartDataNetIncomeRatio} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Research and Development Expenses</Typography>
                            <BarChart data={chartDataResearchAndDevelopmentExpenses} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Selling and Marketing Expenses</Typography>
                            <BarChart data={chartDataSellingAndMarketingExpenses} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Weighted Average Shares Outstanding</Typography>
                            <BarChart data={chartDataWeightedAverageShsOut} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Weighted Average Shares Outstanding (Diluted)</Typography>
                            <BarChart data={chartDataWeightedAverageShsOutDil} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>General and Administrative Expenses</Typography>
                            <BarChart data={chartDataGeneralAndAdministrativeExpenses} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <ContentAccessControl hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Operating Expenses</Typography>
                            <BarChart data={chartDataOperatingExpenses} />
                        </ContentAccessControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ContentAccessControl hasAccess={user.plan.name === 'Premium'} upgradeLink="/upgrade">
                            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Other Expenses</Typography>
                            <BarChart data={chartDataOtherExpenses} />
                        </ContentAccessControl>
                    </Grid>
                </Grid>
            </Box>
        </Container>

    );
};

export default CompanyIncomeStatement;
