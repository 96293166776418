import React, { useEffect, useState } from 'react';
import axios from '../config/axiosConfig';
import { useAuth } from './AuthContext';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, CircularProgress, Button, Modal, Box, FormControl, InputLabel, MenuItem, Select, Snackbar, Container } from '@mui/material';
import {
  AddCircle as AddCircleIcon
} from '@mui/icons-material';
import UpgradePopup from './UpgradePopup';

const Dashboard = () => {
  const { user } = useAuth();
  const [assetsData, setAssetsData] = useState([]);
  const [watchlistData, setWatchlistData] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  const [showUpgradePopup, setShowUpgradePopup] = useState(false); // State for UpgradePopup

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/eod');
        console.log('response', response.data); // Log the response data

        if (response.data && response.data.date && response.data.data) {
          const formattedDate = new Date(response.data.date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
          setFormattedDate(formattedDate);
          setAssetsData(response.data.data);
          setLoading(false);
        } else {
          console.error('Invalid response data:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchWatchlistData = async () => {
        try {
          const response = await axios.get(`/api/watchlists/${user._id}`);
          setWatchlistData(response.data);
        } catch (error) {
          console.error('Error fetching watchlist data:', error);
        }
      };

      fetchWatchlistData();
    }
  }, [user]);

  const handleAddToWatchlist = (asset) => {
    setSelectedAsset(asset);
    setShowAddToWatchlistModal(true);
  };

  const handleAddToSelectedWatchlist = async (watchlistId) => {
    try {
      setSelectedAsset(prevState => ({
        ...prevState,
        watchlistId
      }));
  
      const response = await axios.post('/api/watchlist/addtowatchlistfromdaily', {
        watchlistId,
        asset: selectedAsset
      });
  
      if (response.status === 201) {
        setShowSnackbar(true);
        setSnackbarMessage(`${selectedAsset.symbol} added to watchlist!`);
        setShowAddToWatchlistModal(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setSnackbarMessage('You have reached the limit for your current plan.');
          setShowUpgradePopup(true); // Show upgrade popup when limit is reached
        } else if (error.response.status === 409) {
          setSnackbarMessage('Asset already in watchlist.');
        } else {
          setSnackbarMessage('Error adding asset to watchlist.');
        }
      } else {
        setSnackbarMessage('Error adding asset to watchlist.');
      }
      setShowSnackbar(true);
      console.error('Error adding asset to watchlist:', error);
    }
  };
  

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterAssets = () => {
    if (assetsData) {
      return assetsData.filter(asset =>
        asset.symbol.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return [];
  };

  const columns = [
    { field: 'symbol', headerName: 'Symbol', flex: 1, minWidth: 150 },
    { field: 'open', headerName: 'Open', flex: 1, minWidth: 150 },
    { field: 'high', headerName: 'High', flex: 1, minWidth: 150 },
    { field: 'low', headerName: 'Low', flex: 1, minWidth: 150 },
    { field: 'close', headerName: 'Close', flex: 1, minWidth: 150 },
    { field: 'volume', headerName: 'Volume', flex: 1, minWidth: 150 },
    user && {
      field: 'addToWatchlist',
      headerName: '',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddToWatchlist(params.row)}
        >
          <AddCircleIcon />
        </Button>
      ),
      minWidth: 150,
    },
  ].filter(Boolean);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="table-container">
      <Container maxWidth="xl">
        <h1>Daily Aggregated {formattedDate}</h1>
        <TextField
          label="Search by Symbol"
          variant="outlined"
          value={searchValue}
          onChange={handleSearchChange}
          style={{ marginBottom: '20px' }}
        />
        <div style={{ overflowX: 'auto' }}>
          <DataGrid
            rows={filterAssets()}
            columns={columns}
            getRowId={(row) => row._id}
            disableColumnMenu
            paginationPosition="middle"
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25]}
          />
        </div>

        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />

        <Modal open={showAddToWatchlistModal} onClose={() => setShowAddToWatchlistModal(false)}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 8,
            boxShadow: 4,
          }}>
            <h2>Add to Watchlist</h2>
            <FormControl fullWidth>
              <InputLabel> </InputLabel>
              <Select
                value=""
                onChange={(e) => handleAddToSelectedWatchlist(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {watchlistData && watchlistData.map(watchlist => (
                  <MenuItem key={watchlist._id} value={watchlist._id}>{watchlist.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setShowAddToWatchlistModal(false)} >
              Close
            </Button>
          </Box>
        </Modal>

        <UpgradePopup
          open={showUpgradePopup}
          onClose={() => setShowUpgradePopup(false)}
        />
      </Container>
    </div>
  );
};

export default Dashboard;
