import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const ContentAccessControl = ({ hasAccess, upgradeLink, children }) => {
  return (
    <Box position="relative" display="inline-block" width="100%">
      {!hasAccess && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          sx={{ 
            backdropFilter: 'blur(5px)', // Blurry background
            borderRadius: '10px' // Rounded corners
          }}
        >
          <Typography variant="h6" color="textSecondary" gutterBottom>
            Upgrade Required
          </Typography>
          <Button variant="contained" color="primary" href={upgradeLink}>
            Upgrade
          </Button>
        </Box>
      )}
      <Box>
        {children}
      </Box>
    </Box>
  );
};

export default ContentAccessControl;
