import React, { useState } from 'react';
import { Container, Card, CardContent, FormControl, TextField, InputLabel, Button, Select, MenuItem } from '@mui/material';

const OptionsCalculator = () => {
  const [legs, setLegs] = useState([{ type: 'call', action: 'buy', strikePrice: '', premium: '', currentPrice: '' }]);
  const [strategy, setStrategy] = useState('');

  const predefinedStrategies = {
    'coveredCall': [
      { type: 'stock', action: 'buy', strikePrice: '', premium: '', currentPrice: '' },
      { type: 'call', action: 'sell', strikePrice: '', premium: '', currentPrice: '' }
    ],
    'bullCallSpread': [
      { type: 'call', action: 'buy', strikePrice: '', premium: '', currentPrice: '' },
      { type: 'call', action: 'sell', strikePrice: '', premium: '', currentPrice: '' }
    ],
    'bearPutSpread': [
      { type: 'put', action: 'buy', strikePrice: '', premium: '', currentPrice: '' },
      { type: 'put', action: 'sell', strikePrice: '', premium: '', currentPrice: '' }
    ],
    'longStraddle': [
      { type: 'call', action: 'buy', strikePrice: '', premium: '', currentPrice: '' },
      { type: 'put', action: 'buy', strikePrice: '', premium: '', currentPrice: '' }
    ],
    'longStrangle': [
      { type: 'call', action: 'buy', strikePrice: '', premium: '', currentPrice: '' },
      { type: 'put', action: 'buy', strikePrice: '', premium: '', currentPrice: '' }
    ]
  };

  const selectStrategy = (selectedStrategy) => {
    if (selectedStrategy === '') {
      setLegs([{ type: 'call', action: 'buy', strikePrice: '', premium: '', currentPrice: '' }]);
      setStrategy('');
    } else {
      setStrategy(selectedStrategy);
      setLegs(predefinedStrategies[selectedStrategy]);
    }
  };

  const addLeg = () => {
    setLegs([...legs, { type: 'call', action: 'buy', strikePrice: '', premium: '', currentPrice: '' }]);
  };

  const removeLeg = (index) => {
    const updatedLegs = [...legs];
    updatedLegs.splice(index, 1);
    setLegs(updatedLegs);
  };

  const clearAllLegs = () => {
    setLegs([{ type: 'call', action: 'buy', strikePrice: '', premium: '', currentPrice: '' }]);
  };

  const handleLegChange = (index, key, value) => {
    const updatedLegs = [...legs];
    updatedLegs[index][key] = value;
    setLegs(updatedLegs);
  };

  const calculateTotalProfitLoss = () => {
    let totalProfitLoss = 0;

    legs.forEach((leg) => {
      const strike = parseFloat(leg.strikePrice);
      const prem = parseFloat(leg.premium);
      const current = parseFloat(leg.currentPrice);

      if (!isNaN(strike) && !isNaN(prem) && !isNaN(current)) {
        // const breakEven = leg.action === 'buy' ? strike + prem : strike - prem;
        let profitLoss = 0;

        if (leg.action === 'buy') {
          if (leg.type === 'call') {
            profitLoss = current > strike ? (current - strike - prem) * 100 : -prem;
          } else {
            profitLoss = current < strike ? (strike - current - prem) * 100 : -prem;
          }
        } else {
          if (leg.type === 'call') {
            profitLoss = current > strike ? -((current - strike - prem) * 100) : prem;
          } else {
            profitLoss = current < strike ? -((strike - current - prem) * 100) : prem;
          }
        }

        totalProfitLoss += profitLoss;
      }
    });

    return totalProfitLoss;
  };

  return (
    <div className="table-container">
      <Container maxWidth="xl">
        <Card sx={{ maxWidth: '500px', mx: 'auto', my: 2 }}>
        <CardContent>
        <h2>Options Profit/Loss Calculator</h2>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Select Strategy</InputLabel>
          <Select sx={{ mt: 2 }} value={strategy} onChange={(e) => selectStrategy(e.target.value)}>
            <MenuItem value="">Select Strategy</MenuItem>
            <MenuItem value="coveredCall">Covered Call</MenuItem>
            <MenuItem value="bullCallSpread">Bull Call Spread</MenuItem>
            <MenuItem value="bearPutSpread">Bear Put Spread</MenuItem>
            <MenuItem value="longStraddle">Long Straddle</MenuItem>
            <MenuItem value="longStrangle">Long Strangle</MenuItem>
          </Select>
        </FormControl>
        {legs.map((leg, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Action</InputLabel>
              <Select sx={{ mt: 2 }} value={leg.action} onChange={(e) => handleLegChange(index, 'action', e.target.value)}>
                <MenuItem value="buy">Buy</MenuItem>
                <MenuItem value="sell">Sell</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Option Type</InputLabel>
              <Select sx={{ mt: 2 }} value={leg.type} onChange={(e) => handleLegChange(index, 'type', e.target.value)}>
                <MenuItem value="call">Call</MenuItem>
                <MenuItem value="put">Put</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Strike Price"
              type="number"
              value={leg.strikePrice}
              onChange={(e) => handleLegChange(index, 'strikePrice', e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Premium"
              type="number"
              value={leg.premium}
              onChange={(e) => handleLegChange(index, 'premium', e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Current Price"
              type="number"
              value={leg.currentPrice}
              onChange={(e) => handleLegChange(index, 'currentPrice', e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="secondary" onClick={() => removeLeg(index)}>Remove Leg</Button>
          </div>
        ))}
        <div className="text-center" style={{ textAlign: 'center' }}>
        <Button variant="contained" style={{ marginRight: '10px' }} onClick={addLeg}>Add Leg</Button>
        <Button variant="contained" color="error" onClick={clearAllLegs}>Clear All</Button>
        <div style={{ marginTop: '20px' }}>
          <h3>Total Profit/Loss: {calculateTotalProfitLoss()}</h3>
          </div>
        </div>
          </CardContent>
          </Card>
      </Container>
      </div>
    );
  };
  
  export default OptionsCalculator;
  