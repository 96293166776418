import React, { useState, useEffect } from 'react';
import { Slide, Typography, Fade } from '@mui/material';

const TextCarousel = ({ phrases, intervalDuration = 3000 }) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [intervalDuration]);

  useEffect(() => {
    let timeout;
    if (!fadeIn) {
      timeout = setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setFadeIn(true);
      }, 500); // Adjust this to match the Fade's timeout duration
    }

    return () => clearTimeout(timeout);
  }, [fadeIn, phrases.length]);

  const currentPhrase = phrases[currentPhraseIndex];

  return (
    <div>
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Fade in={fadeIn} timeout={500}>
          <Typography variant="h2" color="primary">
            <strong>{currentPhrase}</strong>
          </Typography>
        </Fade>
      </Slide>
    </div>
  );
};

export default TextCarousel;
