import React from 'react';
import { Bar } from 'react-chartjs-2';
import '../styles.css';

const BarChart = ({ data }) => {
    return (
        <div className="chart-container">
            <div className="chart-wrapper">
                <Bar
                    data={data}
                    options={{
                        plugins: {
                            datalabels: {
                                display: false, // Hide labels inside the chart segments
                            },
                        },
                        scales: {
                            y: {
                                ticks: {
                                    beginAtZero: true,
                                    callback: function(value) { 
                                        if (value % 1 === 0) { 
                                            return value; 
                                        } 
                                    }
                                }
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default BarChart;
