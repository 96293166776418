// components/CompanyHistoricalDividends.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { Container, Box, Grid, CircularProgress, Typography, Switch, FormControlLabel } from '@mui/material';
import BarChart from './BarChart';
import withPlanAccess from './WithPlanAccess';

const CompanyHistoricalDividends = () => {
  const { symbol } = useParams();
  const [historicalDividendsData, setHistoricalDividendsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRawData, setShowRawData] = useState(false);

  useEffect(() => {
    const fetchHistoricalDividendsData = async () => {
      try {
        const response = await axios.get(`/api/historicaldividendsdata/${symbol}`);
        if (response.data.data && Array.isArray(response.data.data)) {
          const historicalData = response.data.data.flatMap(item => item.historical);
          const sortedData = historicalData.sort((a, b) => new Date(a.date) - new Date(b.date));
          setHistoricalDividendsData(sortedData);
        } else {
          throw new Error('Invalid data format');
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchHistoricalDividendsData();
  }, [symbol]);

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h6" color="error">
            Error fetching data: {error.message}
          </Typography>
        </Box>
      </Container>
    );
  }

  if (!historicalDividendsData || historicalDividendsData.length === 0 || !Array.isArray(historicalDividendsData)) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h6" color="textSecondary">
            No data available
          </Typography>
        </Box>
      </Container>
    );
  }

  const chartDataDividends = {
    labels: historicalDividendsData.map(dividend => new Date(dividend.date).toLocaleDateString()),
    datasets: [{
      label: 'Dividend',
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(75,192,192,0.4)',
      hoverBorderColor: 'rgba(75,192,192,1)',
      data: historicalDividendsData.map(dividend => dividend.dividend)
    }]
  };

  return (
    <Container>
      <Box my={4}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={10}>
            <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>Dividend</Typography>
            <BarChart data={chartDataDividends} />
          </Grid>
        </Grid>
      </Box>

      <Box my={4} display="flex" justifyContent="center">
        <FormControlLabel
          control={<Switch checked={showRawData} onChange={() => setShowRawData(!showRawData)} />}
          label="Show Raw Data"
        />
      </Box>

      {showRawData && (
        <Box my={4}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
            Historical Dividends for {symbol}
          </Typography>
          <Grid container spacing={2}>
            {historicalDividendsData.map((dividend, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box border={1} borderColor="grey.300" borderRadius={4} p={2}>
                  <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                    {dividend.label}
                  </Typography>
                  <Typography variant="subtitle1"><strong>Adj Dividend:</strong> {dividend.adjDividend}</Typography>
                  <Typography variant="subtitle1"><strong>Dividend:</strong> {dividend.dividend}</Typography>
                  <Typography variant="subtitle1"><strong>Record Date:</strong> {new Date(dividend.recordDate).toLocaleDateString()}</Typography>
                  <Typography variant="subtitle1"><strong>Payment Date:</strong> {new Date(dividend.paymentDate).toLocaleDateString()}</Typography>
                  <Typography variant="subtitle1"><strong>Declaration Date:</strong> {new Date(dividend.declarationDate).toLocaleDateString()}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

const allowedPlans = ['Basic', 'Premium'];
export default withPlanAccess(CompanyHistoricalDividends, allowedPlans);
