import { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';

function useCountries() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await axios.get('/api/countrycodes');
        setCountries(response.data.data.map(country => country.code));
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    }

    fetchCountries();
  }, []);

  return countries;
}

export default useCountries;
