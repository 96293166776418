import React from 'react';
import { Typography, List, ListItem, Button } from '@mui/material';

const PricingPlan = ({ price, title, description, features, btn, isMonthly }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography variant="h4" style={{ marginBottom: '1rem' }}>
                {price}
            </Typography>
            {!isMonthly && (
                <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                    billed annually
                </Typography>
            )}
            <Typography variant="h5" style={{ marginBottom: '1rem' }}>
                {title}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '1rem' }}>
                {description}
            </Typography>
            <List style={{ textAlign: 'center' }}>
                {features.map((feature, index) => (
                    <ListItem key={index}>{feature}</ListItem>
                ))}
            </List>
            <Button variant="contained" color="primary">
                {btn}
            </Button>
        </div>
    );
};

export default PricingPlan;
