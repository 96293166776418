import React from 'react';
import {
  Modal,
  Button,
  Box,
} from '@mui/material';

const DeleteModal = ({ open, onClose, onConfirm, itemName }) => {
    
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 8,
        boxShadow: 4,
        textAlign: 'center',
      }}>
        <h2>{`Are you sure you want to delete "${itemName}"?`}</h2>
        <Box sx={{ mt: 2 }}>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
