import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Grid, Snackbar } from '@mui/material';
import axios from '../config/axiosConfig';

const ProfileDetails = () => {
  const [profile, setProfile] = useState({
    profilePicture: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: ''
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phone: ''
  });

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    // Fetch user profile data on component mount
    const fetchProfile = async () => {
      try {
        const response = await axios.get('/api/user/profile');
        setProfile(response.data.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });

    // Validate phone number
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {
        setErrors({ ...errors, phone: '' }); // Clear error if valid
      } else {
        setErrors({ ...errors, phone: 'Phone number must contain only numbers' });
      }
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {
      firstName: '',
      lastName: '',
      phone: ''
    };

    if (!profile.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
      isValid = false;
    }

    if (!profile.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
      isValid = false;
    }

    if (!/^\d*$/.test(profile.phone)) {
      newErrors.phone = 'Phone number must contain only numbers';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    if (validate()) {
      try {
        await axios.put('/api/user/profile', profile);
        setSnackbarMessage('Profile updated successfully');
        setShowSnackbar(true);
      } catch (error) {
        console.error('Error updating profile data:', error);
        setSnackbarMessage('Failed to update profile');
        setShowSnackbar(true);
      }
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>Profile Details</Typography>
      {/* <Avatar src={profile.profilePicture} alt="Profile Picture" style={{ width: 100, height: 100 }} /> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={profile.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={profile.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={profile.email}
            onChange={handleChange}
            disabled
          />
        </Grid>
        {/*<Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={profile.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={profile.address}
            onChange={handleChange}
          /> 
        </Grid>*/}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>

      {/* Snackbar Component */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
};

export default ProfileDetails;
