import { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';

function useSectors() {
  const [Sectors, setSectors] = useState([]);

  useEffect(() => {
    async function fetchSectors() {
      try {
        const response = await axios.get('/api/sectors');
        setSectors(response.data.data.map(sector => sector.sector));
      } catch (error) {
        console.error('Error fetching Sectors:', error);
      }
    }

    fetchSectors();
  }, []);

  return Sectors;
}

export default useSectors;
