import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { Container, Typography, CircularProgress, Alert, Box, Button } from '@mui/material';

const EmailVerification = () => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const success = queryParams.get('success');

                // If success is true, skip token verification
                if (success === 'true') {
                    setMessage('Email verified successfully. You can now log in.');
                    setLoading(false);
                    return;
                }

                const token = queryParams.get('token');

                if (!token) {
                    setMessage('Invalid or missing token');
                    setLoading(false);
                    return;
                }

                const response = await axios.get(`/verify-email?token=${token}`, {
                    withCredentials: true
                });

                if (response.data.success) {
                    setMessage('Email verified successfully. You can now log in.');
                } else {
                    setMessage('Email verification failed. The token may be invalid or expired.');
                }
            } catch (error) {
                console.error('Error during email verification:', error);
                setMessage('An error occurred during email verification. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [location.search]);

    return (
        <Container sx={{ mt: 6, mb: 15 }}>
            <Box textAlign="center">
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Alert severity={message.includes('successfully') ? 'success' : 'error'}>
                        {message}
                    </Alert>
                )}
                <Typography variant="h6" sx={{ mt: 2 }}>
                    {message.includes('successfully') && (
                        <Button variant="contained" onClick={() => navigate('/')}>
                            Start Here
                        </Button>
                    )}
                </Typography>
            </Box>
        </Container>
    );
};

export default EmailVerification;
