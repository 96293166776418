import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import axios from '../config/axiosConfig';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Container,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  TablePagination,
  Typography
} from '@mui/material';
import useExchanges from '../hooks/useExchanges';
import TitleAndInfo from './TitleAndInfo';

const QuotesScreener = () => {
  const exchanges = useExchanges();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    priceMoreThan: '',
    priceLowerThan: '',
    changesPercentageMoreThan: '',
    changesPercentageLowerThan: '',
    changeMoreThan: '',
    changeLowerThan: '',
    dayLowMoreThan: '',
    dayLowLowerThan: '',
    dayHighMoreThan: '',
    dayHighLowerThan: '',
    yearHighMoreThan: '',
    yearHighLowerThan: '',
    yearLowMoreThan: '',
    yearLowLowerThan: '',
    marketCapMoreThan: '',
    marketCapLowerThan: '',
    priceAvg50MoreThan: '',
    priceAvg50LowerThan: '',
    priceAvg200MoreThan: '',
    priceAvg200LowerThan: '',
    volumeMoreThan: '',
    volumeLowerThan: '',
    avgVolumeMoreThan: '',
    avgVolumeLowerThan: '',
    openMoreThan: '',
    openLowerThan: '',
    previousCloseMoreThan: '',
    previousCloseLowerThan: '',
    epsMoreThan: '',
    epsLowerThan: '',
    peMoreThan: '',
    peLowerThan: '',
    earningsAnnouncement: '',
    sharesOutstandingMoreThan: '',
    sharesOutstandingLowerThan: '',
    exchange: '',
  });

  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [, setSearchResults] = useState(true);
  const baseUrl = window.location.origin;

  const title = "Advanced Stock Quote Screener: Comprehensive Market Data for Precision Trading";
  const info = `The Advanced Stock Quote Screener offers in-depth stock data. This tool provides essential metrics. Use this screener to access vital stock information, refine your trading strategies, and make well-informed investment decisions. Perfect for traders and investors seeking detailed market insights for effective trading.`;

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    setStocks([]);
    setPage(0);
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('/api/filteredquotes', formData);
      const responseData = response.data.data;
      setStocks(responseData);
      setSearchResults(responseData.length > 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleClearFilters = () => {
    setFormData({
      priceMoreThan: '',
      priceLowerThan: '',
      changesPercentageMoreThan: '',
      changesPercentageLowerThan: '',
      changeMoreThan: '',
      changeLowerThan: '',
      dayLowMoreThan: '',
      dayLowLowerThan: '',
      dayHighMoreThan: '',
      dayHighLowerThan: '',
      yearHighMoreThan: '',
      yearHighLowerThan: '',
      yearLowMoreThan: '',
      yearLowLowerThan: '',
      marketCapMoreThan: '',
      marketCapLowerThan: '',
      priceAvg50MoreThan: '',
      priceAvg50LowerThan: '',
      priceAvg200MoreThan: '',
      priceAvg200LowerThan: '',
      volumeMoreThan: '',
      volumeLowerThan: '',
      avgVolumeMoreThan: '',
      avgVolumeLowerThan: '',
      openMoreThan: '',
      openLowerThan: '',
      previousCloseMoreThan: '',
      previousCloseLowerThan: '',
      epsMoreThan: '',
      epsLowerThan: '',
      peMoreThan: '',
      peLowerThan: '',
      earningsAnnouncement: '',
      sharesOutstandingMoreThan: '',
      sharesOutstandingLowerThan: '',
      exchange: '',
    });
    setStocks([]);
  };

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Calculate the index of the first and last item to display on the current page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  useEffect(() => {
    setSearchResults(stocks.length > 0);
  }, [stocks]);

  return (
    <div>
      <Container sx={{ mt: 4 }}>
        <TitleAndInfo title={title} info={info} />
        <form onSubmit={handleSubmit}>
          <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: '5px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Price More Than"
                  name="priceMoreThan"
                  type="number"
                  value={formData.priceMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Price Lower Than"
                  name="priceLowerThan"
                  type="number"
                  value={formData.priceLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Changes % More Than"
                  name="changesPercentageMoreThan"
                  type="number"
                  value={formData.changesPercentageMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Changes % Lower Than"
                  name="changesPercentageLowerThan"
                  type="number"
                  value={formData.changesPercentageLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Change More Than"
                  name="changeMoreThan"
                  type="number"
                  value={formData.changeMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Change Lower Than"
                  name="changeLowerThan"
                  type="number"
                  value={formData.changeLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Day Low More Than"
                  name="dayLowMoreThan"
                  type="number"
                  value={formData.dayLowMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Day Low Lower Than"
                  name="dayLowLowerThan"
                  type="number"
                  value={formData.dayLowLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Day High More Than"
                  name="dayHighMoreThan"
                  type="number"
                  value={formData.dayHighMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Day High Lower Than"
                  name="dayHighLowerThan"
                  type="number"
                  value={formData.dayHighLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Year High More Than"
                  name="yearHighMoreThan"
                  type="number"
                  value={formData.yearHighMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Year High Lower Than"
                  name="yearHighLowerThan"
                  type="number"
                  value={formData.yearHighLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Year Low More Than"
                  name="yearLowMoreThan"
                  type="number"
                  value={formData.yearLowMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Year Low Lower Than"
                  name="yearLowLowerThan"
                  type="number"
                  value={formData.yearLowLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Market Cap More Than"
                  name="marketCapMoreThan"
                  type="number"
                  value={formData.marketCapMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Market Cap Lower Than"
                  name="marketCapLowerThan"
                  type="number"
                  value={formData.marketCapLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              {/* <Grid item xs={12} sm={3}>
                <TextField
                  label="50 Day Avg Price More Than"
                  name="priceAvg50MoreThan"
                  type="number"
                  value={formData.priceAvg50MoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="50 Day Avg Price Lower Than"
                  name="priceAvg50LowerThan"
                  type="number"
                  value={formData.priceAvg50LowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="200 Day Avg Price More Than"
                  name="priceAvg200MoreThan"
                  type="number"
                  value={formData.priceAvg200MoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              
              <Grid item xs={12} sm={3}>
                <TextField
                  label="200 Day Avg Price Lower Than"
                  name="priceAvg200LowerThan"
                  type="number"
                  value={formData.priceAvg200LowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              */}
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Volume More Than"
                  name="volumeMoreThan"
                  type="number"
                  value={formData.volumeMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Volume Lower Than"
                  name="volumeLowerThan"
                  type="number"
                  value={formData.volumeLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Avg Volume More Than"
                  name="avgVolumeMoreThan"
                  type="number"
                  value={formData.avgVolumeMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Avg Volume Lower Than"
                  name="avgVolumeLowerThan"
                  type="number"
                  value={formData.avgVolumeLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Open More Than"
                  name="openMoreThan"
                  type="number"
                  value={formData.openMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Open Lower Than"
                  name="openLowerThan"
                  type="number"
                  value={formData.openLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Previous Close More Than"
                  name="previousCloseMoreThan"
                  type="number"
                  value={formData.previousCloseMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Previous Close Lower Than"
                  name="previousCloseLowerThan"
                  type="number"
                  value={formData.previousCloseLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="EPS More Than"
                  name="epsMoreThan"
                  type="number"
                  value={formData.epsMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="EPS Lower Than"
                  name="epsLowerThan"
                  type="number"
                  value={formData.epsLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="PE Ratio More Than"
                  name="peMoreThan"
                  type="number"
                  value={formData.peMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="PE Ratio Lower Than"
                  name="peLowerThan"
                  type="number"
                  value={formData.peLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Earnings Announcement"
                  name="earningsAnnouncement"
                  type="text"
                  value={formData.earningsAnnouncement}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Shares Outstanding More Than"
                  name="sharesOutstandingMoreThan"
                  type="number"
                  value={formData.sharesOutstandingMoreThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Shares Outstanding Lower Than"
                  name="sharesOutstandingLowerThan"
                  type="number"
                  value={formData.sharesOutstandingLowerThan}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Exchange</InputLabel>
                  <Select
                    label="Exchange"
                    name="exchange"
                    value={formData.exchange}
                    onChange={handleChange}
                  >
                    {exchanges.map((exchange) => (
                      <MenuItem key={exchange} value={exchange}>
                        {exchange}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button variant="outlined" size="small" onClick={handleClearFilters}>
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary" size="small">
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>

      {loading && (
        <Box sx={{ padding: 2, borderRadius: '5px' }}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      )}

      {/* Show message if no results */}
      {!loading && stocks.length === 0 && Object.values(formData).some(value => value !== '') && (
        <Box sx={{ padding: 2, borderRadius: '5px', textAlign: 'center', marginTop: 4 }}>
          <Typography variant="body1">No results found.</Typography>
        </Box>
      )}



      {stocks.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Symbol</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Changes Percentage</TableCell>
                <TableCell>Change</TableCell>
                <TableCell>Day Low</TableCell>
                <TableCell>Day High</TableCell>
                <TableCell>Year High</TableCell>
                <TableCell>Year Low</TableCell>
                <TableCell>Market Cap</TableCell>
                <TableCell>50 Day Avg Price</TableCell>
                <TableCell>200 Day Avg Price</TableCell>
                <TableCell>Exchange</TableCell>
                <TableCell>Volume</TableCell>
                <TableCell>Avg Volume</TableCell>
                <TableCell>Open</TableCell>
                <TableCell>Previous Close</TableCell>
                <TableCell>EPS</TableCell>
                <TableCell>PE</TableCell>
                <TableCell>Earnings Announcement</TableCell>
                <TableCell>Shares Outstanding</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stocks.slice(startIndex, endIndex).map((stock, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <RouterLink to={`${baseUrl}/Stock/${stock.symbol}`} style={{ color: theme.palette.primary.main }}>
                      {stock.symbol}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{stock.name}</TableCell>
                  <TableCell>{stock.price}</TableCell>
                  <TableCell>{stock.changesPercentage}</TableCell>
                  <TableCell>{stock.change}</TableCell>
                  <TableCell>{stock.dayLow}</TableCell>
                  <TableCell>{stock.dayHigh}</TableCell>
                  <TableCell>{stock.yearHigh}</TableCell>
                  <TableCell>{stock.yearLow}</TableCell>
                  <TableCell>{stock.marketCap}</TableCell>
                  <TableCell>{stock.priceAvg50}</TableCell>
                  <TableCell>{stock.priceAvg200}</TableCell>
                  <TableCell>{stock.exchange}</TableCell>
                  <TableCell>{stock.volume}</TableCell>
                  <TableCell>{stock.avgVolume}</TableCell>
                  <TableCell>{stock.open}</TableCell>
                  <TableCell>{stock.previousClose}</TableCell>
                  <TableCell>{stock.eps}</TableCell>
                  <TableCell>{stock.pe}</TableCell>
                  <TableCell>{new Date(stock.earningsAnnouncement).toLocaleDateString()}</TableCell>
                  <TableCell>{stock.sharesOutstanding}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
            component="div"
            count={stocks.length} // Total number of items
            rowsPerPage={rowsPerPage} // Number of items per page
            page={page} // Current page
            onPageChange={(event, newPage) => setPage(newPage)} // Handle page change
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0); // Reset page to 0 when rows per page changes
            }}
          />
        </TableContainer>

      )}
    </div>
  );
};

export default QuotesScreener;
