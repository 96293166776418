import { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';

function useIndustry() {
  const [Industry, setIndustry] = useState([]);

  useEffect(() => {
    async function fetchIndustry() {
      try {
        const response = await axios.get('/api/industries');
        setIndustry(response.data.data.map(industry => industry.industry));
      } catch (error) {
        console.error('Error fetching Industry:', error);
      }
    }

    fetchIndustry();
  }, []);

  return Industry;
}

export default useIndustry;
