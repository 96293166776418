// TitleAndInfo.js
import React from 'react';
import { Typography, Box } from '@mui/material';

const TitleAndInfo = ({ title, info }) => {
  return (
    <Box sx={{ marginBottom: 6, paddingTop: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="body1">
        {info}
      </Typography>
    </Box>
  );
};

export default TitleAndInfo;
