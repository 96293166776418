import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Snackbar, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from '../config/axiosConfig';

const AccountSettings = () => {
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  const handleSave = async () => {
    // Check for empty passwords
    if (!passwords.currentPassword || !passwords.newPassword || !passwords.confirmNewPassword) {
      setSnackbarMessage('All fields are required.');
      setShowSnackbar(true);
      return;
    }

    // Check if new passwords match
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      setSnackbarMessage('New passwords do not match');
      setShowSnackbar(true);
      return;
    }

    try {
      await axios.put('/api/user/account', {
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword
      });
      setSnackbarMessage('Password updated successfully');
      setShowSnackbar(true);
      setPasswords({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      });
    } catch (error) {
      console.error('Error updating password:', error);
      setSnackbarMessage('Failed to update password');
      setShowSnackbar(true);
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>Account Settings</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type={showPassword.currentPassword ? 'text' : 'password'}
            label="Current Password"
            name="currentPassword"
            value={passwords.currentPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword('currentPassword')}
                    edge="end"
                  >
                    {showPassword.currentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type={showPassword.newPassword ? 'text' : 'password'}
            label="New Password"
            name="newPassword"
            value={passwords.newPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword('newPassword')}
                    edge="end"
                  >
                    {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type={showPassword.confirmNewPassword ? 'text' : 'password'}
            label="Confirm New Password"
            name="confirmNewPassword"
            value={passwords.confirmNewPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword('confirmNewPassword')}
                    edge="end"
                  >
                    {showPassword.confirmNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
};

export default AccountSettings;
