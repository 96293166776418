import React, { useState, useEffect } from 'react';
import { Grid, Box, Card, CardContent, Typography, Container, CircularProgress, ButtonGroup, Button, TextField } from '@mui/material';

const CryptoArbitrageScanner = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [selectedCrypto,] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const newSocket = new WebSocket('ws://localhost:5000');

    newSocket.onopen = () => {
      console.log('WebSocket connection established');
      newSocket.send(JSON.stringify({ action: 'subscribe', channel: 'topCryptos'}));
    };

    newSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const { source, data: newData } = message;
      
      if (source === 'topCryptos') {
        setCryptoData(prevData => {
          // Check if newData already exists in the data array
          const index = prevData.findIndex(item => item.symbol === newData.symbol && item.exchange === newData.exchange);
    
          if (index !== -1) {
            // If newData exists, update the corresponding entry
            return prevData.map((item, i) => {
              if (i === index) {
                // Merge newData with existing item
                return { ...item, ...newData };
              }
              return item;
            });
          } else {
            // If newData doesn't exist, add it to the array
            return [...prevData, newData];
          }
        });
        setLoading(false);
      }
    };

    newSocket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, [selectedCrypto]);

  // const handleCryptoChange = (event) => {
  //   setSelectedCrypto(event.target.value);
  //   setSelectedCurrency('');
  //   setLoading(true);
  // };

  const handleCurrencyFilter = (currency) => {
    setSelectedCurrency(currency);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const findLowestAndHighestPrices = (symbol) => {
    const symbolData = cryptoData.filter(crypto => crypto.symbol === symbol);
    const sortedPrices = symbolData.map(crypto => crypto.price).sort((a, b) => a - b);
    const lowestPrice = sortedPrices[0];
    const highestPrice = sortedPrices[sortedPrices.length - 1];
    return { lowestPrice, highestPrice };
  };

  // Filter data to exclude symbols with only one occurrence
  const filteredData = cryptoData.filter(crypto => {
    return (crypto.symbol.toLowerCase().includes(searchQuery.toLowerCase()) || crypto.exchange.toLowerCase().includes(searchQuery.toLowerCase())) && (!selectedCurrency || crypto.symbol.includes(selectedCurrency));
  });

  // Count occurrences of each symbol
  const symbolCounts = filteredData.reduce((counts, crypto) => {
    counts[crypto.symbol] = (counts[crypto.symbol] || 0) + 1;
    return counts;
  }, {});

  // Filter out symbols that appear only once
  const symbolsWithMultipleOccurrences = Object.keys(symbolCounts).filter(symbol => symbolCounts[symbol] > 1);

  // Filter the filteredData array to include only symbols with multiple occurrences
  const filteredDataWithMultipleOccurrences = filteredData.filter(crypto => symbolsWithMultipleOccurrences.includes(crypto.symbol));

  return (
    <Container maxWidth="s">
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <ButtonGroup 
                  variant="outlined" 
                  aria-label="currency-filter"
                  sx={{ marginLeft: 'auto' }}
                >
                  <Button
                    onClick={() =>
                      handleCurrencyFilter(selectedCurrency === 'USD' ? '' : 'USD')
                    }
                    variant={selectedCurrency === 'USD' ? 'contained' : 'outlined'}
                  >
                    USD
                  </Button>
                  <Button
                    onClick={() =>
                      handleCurrencyFilter(selectedCurrency === 'EUR' ? '' : 'EUR')
                    }
                    variant={selectedCurrency === 'EUR' ? 'contained' : 'outlined'}
                  >
                    EUR
                  </Button>
                  <Button
                    onClick={() =>
                      handleCurrencyFilter(selectedCurrency === 'GBP' ? '' : 'GBP')
                    }
                    variant={selectedCurrency === 'GBP' ? 'contained' : 'outlined'}
                  >
                    GBP
                  </Button>
                </ButtonGroup>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : filteredDataWithMultipleOccurrences.length ? (
          filteredDataWithMultipleOccurrences.map((crypto, idx) => {
            const { lowestPrice, highestPrice } = findLowestAndHighestPrices(crypto.symbol);
            const backgroundColor = crypto.price === lowestPrice ? 'green' : crypto.price === highestPrice ? 'red' : 'inherit';
            return (
              <Grid item xs={12} sm={6} md={3} lg={4} xl={2} key={idx}>
                <Card sx={{ 
                  marginBottom: 2, 
                  border: '1px solid #ccc', 
                  borderRadius: '4px', 
                  maxWidth: '100%', 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  backgroundColor // Apply background color here
                }}>
                  <CardContent sx={{ 
                    flex: '1 1 auto', 
                    textAlign: 'center' 
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h5" component="h2" sx={{ marginBottom: 1, textAlign: 'left' }}>
                        {crypto.symbol}
                      </Typography>
                      <div style={{ textAlign: 'left' }}>
                        <Typography color="textSecondary" sx={{ marginBottom: 0.5 }}>
                          Price: {crypto.price}
                        </Typography>
                        <Typography color="textSecondary" sx={{ marginBottom: 0.5 }}>
                          Bid Price: {crypto.bidPrice}
                        </Typography>
                        <Typography color="textSecondary" sx={{ marginBottom: 0.5 }}>
                          Ask Price: {crypto.askPrice}
                        </Typography>
                        <Typography color="textSecondary">
                          Exchange: {crypto.exchange}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              {selectedCrypto ? 'No data available for the selected currency.' : 'Please select a cryptocurrency.'}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CryptoArbitrageScanner;
