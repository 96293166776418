import React from 'react';
import { TableBody, TableContainer, Table, TableHead, TableRow, TableCell, Paper } from '@mui/material';

const DataTable = ({ dataArray, headline }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Symbol</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Percentage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataArray.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {item.label}
                            </TableCell>
                            <TableCell align="right">{item.value}</TableCell>
                            <TableCell align="right">{item.percentage}%</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;
