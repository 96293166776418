import React, { useState } from 'react';
import { Container, Card, CardContent, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

// Function to calculate the cumulative distribution function of the standard normal distribution
const normcdf = (x) => {
  const neg = (x < 0);
  if (neg) x *= -1;

  const k = 1 / (1 + 0.2316419 * x);
  let y = ((((1.330274429 * k - 1.821255978) * k + 1.781477937) * k - 0.356563782) * k + 0.319381530) * k;
  y = 1.0 - 0.398942280401 * Math.exp(-0.5 * x * x) * y;

  return (neg ? 1.0 - y : y);
};

const BlackScholesCalculator = () => {
  const [stockPrice, setStockPrice] = useState('');
  const [strikePrice, setStrikePrice] = useState('');
  const [timeToExpiration, setTimeToExpiration] = useState('');
  const [volatility, setVolatility] = useState('');
  const [riskFreeRate, setRiskFreeRate] = useState('');
  const [optionType, setOptionType] = useState('call');
  const [callPrice, setCallPrice] = useState('');
  const [putPrice, setPutPrice] = useState('');

  const calculateOptionPrice = () => {
    const S = parseFloat(stockPrice);
    const X = parseFloat(strikePrice);
    const T = parseFloat(timeToExpiration);
    const sigma = parseFloat(volatility) / 100; // Convert volatility to decimal
    const r = parseFloat(riskFreeRate) / 100; // Convert risk-free rate to decimal

    const d1 = (Math.log(S / X) + (r + (sigma * sigma) / 2) * T) / (sigma * Math.sqrt(T));
    const d2 = d1 - sigma * Math.sqrt(T);

    if (optionType === 'call') {
      const callPrice = S * normcdf(d1) - X * Math.exp(-r * T) * normcdf(d2);
      setCallPrice(callPrice.toFixed(2));
      setPutPrice(''); // Reset put price
    } else {
      const putPrice = X * Math.exp(-r * T) * normcdf(-d2) - S * normcdf(-d1);
      setPutPrice(putPrice.toFixed(2));
      setCallPrice(''); // Reset call price
    }
  };

  return (
    <div className="table-container">
      <Container maxWidth="xl">
        <Card sx={{ maxWidth: '500px', mx: 'auto', my: 2 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Black-Scholes Options Calculator
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              id="stockPrice"
              label="Stock Price"
              type="number"
              value={stockPrice}
              onChange={(e) => setStockPrice(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              id="strikePrice"
              label="Strike Price"
              type="number"
              value={strikePrice}
              onChange={(e) => setStrikePrice(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              id="timeToExpiration"
              label="Time to Expiration (in years)"
              type="number"
              value={timeToExpiration}
              onChange={(e) => setTimeToExpiration(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              id="volatility"
              label="Volatility (%)"
              type="number"
              value={volatility}
              onChange={(e) => setVolatility(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              id="riskFreeRate"
              label="Risk-Free Interest Rate (%)"
              type="number"
              value={riskFreeRate}
              onChange={(e) => setRiskFreeRate(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="optionTypeLabel"> </InputLabel>
            <Select
              labelId="optionTypeLabel"
              id="optionType"
              value={optionType}
              onChange={(e) => setOptionType(e.target.value)}
            >
              <MenuItem value="call">Call</MenuItem>
              <MenuItem value="put">Put</MenuItem>
            </Select>
          </FormControl>
          <div className="text-center" style={{ textAlign: 'center' }}>
            <Button variant="contained" color="primary" onClick={calculateOptionPrice} sx={{ mx: 'auto', mt: 2 }}>
              Calculate Option Price
            </Button>
            {callPrice && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Call Price: ${callPrice}
              </Typography>
            )}
            {putPrice && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Put Price: ${putPrice}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </Container>
    </div>
  );
};

export default BlackScholesCalculator;
