import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../config/axiosConfig';
import {
  useTheme,
  Typography,
  Alert,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Input,
  Button,
  Box
} from '@mui/material';
import { useAuth } from './AuthContext';
import logo from '../images/rodeoLogo.png';

const Login = () => {
  const theme = useTheme();
  const { /* Signup, */ setUser, setIsAuthenticated } = useAuth();
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // const handleGoogleSignup = () => {
  //   Signup();
  // };


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to your backend API to handle Login
      const response = await axios.post('/auth/locallogin', formData);
      console.log('Login successful:', response.data);
      if (response && response.data && response.data.success) {
        const user = response.data.user;
        // Check if the user is verified before proceeding
        if (!user.isVerified) {
          setError('Email verification required. Please verify your email to login.');
          return;
        }
        // Update user state in AuthContext after successful Login
        setUser(user);
        setIsAuthenticated(response.data.success);
        navigate('/');
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Error login:', error);
      setError(error.response.data.message);
    }
  };

  return (
    <Container sx={{ mt: 6, mb: 15 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box boxShadow={3} p={3} borderRadius={2}>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 4 }}>
                <img src={logo} alt="Market Rodeo Logo" style={{ height: 'auto', width: '69px' }} />
              </Typography>
              <Typography variant="h2" align="center" gutterBottom sx={{ color: theme.palette.primary.main }}>
                <strong>Log in</strong>
              </Typography>
              <Typography variant="h6" align="center" gutterBottom sx={{ color: theme.palette.third.main }}>
                Enter your login details to access your account.
              </Typography>
            </div>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleLogin}>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel htmlFor="email">Email address</InputLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </FormControl>

              <div className="text-center" style={{ textAlign: 'center' }}>
                <div>
                  <Button variant="contained" type="submit" sx={{ mt: 3 }}>
                    Log in
                  </Button>
                </div>
              {/*  <div className="mt-3">
                   <Button onClick={handleGoogleSignup} variant="outlined" sx={{ mt: 3 }}>
                    Log in with Google
                  </Button>
                </div> */}
              </div> 
            </form>
          {/*  <Typography variant="h6" align="center" gutterBottom sx={{ mt: 2 }}>
              Don't have an account yet? <strong><Link to="/signup">Sign Up</Link></strong>
            </Typography> */}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
