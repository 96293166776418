// src/components/DoughnutChartComponent.jsx

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the plugin
Chart.register(ChartDataLabels);

const DoughnutChartComponent = ({ chartData, options }) => {
    return <Doughnut data={chartData} options={options} />;
};

export default DoughnutChartComponent;
