// components/UpgradePopup.js
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UpgradePopup = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleUpgrade = () => {
    onClose();
    navigate('/upgrade'); // Assuming you have an upgrade page at /upgrade
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Upgrade Required</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have reached the limit for your current plan. Please upgrade to access more features.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleUpgrade} color="primary" autoFocus>Upgrade</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradePopup;
