import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import CreateModal from './CreateModal';
import DeleteModal from './DeleteModal';
import UpgradePopup from './UpgradePopup';
import axios from '../config/axiosConfig';
import {
  Container,
  Snackbar,
  Modal,
  Typography,
  Button,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete as DeleteIcon } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import AddPortfolioAsset from './AddPortfolioAsset';
import DoughnutChart from '../components/DoughnutChart'

const Portfolio = ({ user }) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [, setNewPortfolioName] = useState('');
  const [showAddPortfolioAssetModal, setShowAddPortfolioAssetModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [, setModalOpenStates] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [dailyChange, setDailyChange] = useState(0);
  const [worstPerformer, setWorstPerformer] = useState(null);
  const [bestPerformer, setBestPerformer] = useState(null);

  const baseUrl = window.location.origin;

  useEffect(() => {
    fetchPortfolios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showSnackbar) {
      fetchPortfolios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSnackbar]);

  useEffect(() => {
    setSelectedPortfolio(portfolios.find(portfolio => portfolio.id === selectedPortfolioId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPortfoliosHoldings(portfolios);
    }, 60000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios]);

  useEffect(() => {
    setSelectedPortfolio(portfolios[selectedTabIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios]);

  useEffect(() => {
    if (selectedPortfolio) {
      calculateTotals(selectedPortfolio.holdings);
      const combinedHoldings = aggregatedHoldings(selectedPortfolio.holdings);
      calculatePerformers(combinedHoldings);
    }
  }, [selectedPortfolio]);



  const fetchPortfolios = async () => {
    try {
      const response = await axios.get(`/api/portfolios/${user._id}`);
      setPortfolios(response.data);
      if (response.data.length > 0) {
        setSelectedPortfolio(response.data[0]);
      }
      setModalOpenStates(Array(response.data.length).fill(false));
      await fetchPortfoliosHoldings(response.data);
    } catch (error) {
      console.error('Error fetching portfolios:', error);
    }
  };

  const fetchPortfoliosHoldings = async (portfoliosData) => {
    try {
      const response = await axios.get(`/api/portfolios/holdings/${user._id}`);
      const quoteData = response.data;
      const updatedPortfolios = portfoliosData.map(portfolio => {
        const updatedHoldings = portfolio.holdings.map(holding => {
          const quote = quoteData.find(q => q.symbol === holding.symbol);
          // Extract specific fields from the quote object
          const { name, exchange, price: quotePrice, changesPercentage, change } = quote || {};
          return {
            ...holding,
            // Assign extracted fields to the holding
            name,
            exchange,
            quotePrice,
            changesPercentage,
            change
          };
        });
        return {
          ...portfolio,
          holdings: updatedHoldings,
        };
      });

      setPortfolios(updatedPortfolios);
      console.log('Updated portfolios:', updatedPortfolios);
    } catch (error) {
      console.error('Error fetching holdings quote data:', error);
    }
  };

  const calculateTotals = (holdings) => {
    console.log('holdings ' + holdings);
    let totalCost = 0;
    let totalValue = 0;
    let dailyChange = 0;

    holdings.forEach(holding => {
      totalCost += holding.quantity * holding.price;
      totalValue += holding.quantity * holding.quotePrice;
      dailyChange += holding.change;

    });

    setTotalCost(totalCost);
    setTotalValue(totalValue);
    setDailyChange(dailyChange);
  };



  const handleAssetDelete = async (portfolioId, holdingId) => {
    try {
      console.log('portfolioId ' + portfolioId)
      console.log('holdingId', holdingId); // Pretty print the params object

      await axios.delete(`/api/deleteasset/${portfolioId}/${holdingId}`);
      setShowSnackbar(true);
      setSnackbarMessage('Asset deleted successfully.');
      fetchPortfolios(); // Fetch portfolios after deletion to reflect the change
      // Close the edit modal if it is open
      if (showEditModal) {
        setShowEditModal(false);
      }
    } catch (error) {
      console.error('Error deleting Asset:', error);
    }
  };



  const handlePortfolioSelect = (portfolio) => {
    setSelectedPortfolioId(portfolio.id);
  };

  const handleCreatePortfolio = async (itemName) => {
    try {
      const response = await axios.post('/api/portfolios', {
        userId: user._id,
        holdings: [],
        name: itemName,
      });
      setShowSnackbar(true);
      setSnackbarMessage('Portfolio created successfully.');
      fetchPortfolios();
      setSelectedPortfolio(response.data);
      setNewPortfolioName('');
      // Close the create modal after creating the portfolio
      setShowCreateModal(false);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSnackbarMessage('You have reached the limit for your current plan.');
        setShowUpgradePopup(true);
      } else {
        setSnackbarMessage('Error creating Portfolio.');
      }
      setShowSnackbar(true);
      console.error('Error creating Portfolio:', error);
    }
  };

  const handleCloseModalAndRefetchData = () => {
    setShowAddPortfolioAssetModal(false);
    fetchPortfolios();
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
    setSelectedPortfolio(null); // Clear the current table
    setTimeout(() => {
      const selectedPortfolio = portfolios[newValue];
      setSelectedPortfolio(selectedPortfolio);
      handlePortfolioSelect(selectedPortfolio);
    }, 0);
  };


  // const handleModalClose = () => {
  //   const updatedModalOpenStates = [...modalOpenStates];
  //   updatedModalOpenStates[selectedTabIndex] = false;
  //   setModalOpenStates(updatedModalOpenStates);
  // };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleUpgradePopupClose = () => {
    setShowUpgradePopup(false);
  };

  const handlePortfolioDelete = async (portfolio) => {
    try {
      await axios.delete(`/api/portfolios/${portfolio._id}`);
      setShowSnackbar(true);
      setSnackbarMessage(`Portfolio "${portfolio.name}" deleted successfully.`);
      setShowDeleteModal(false);
      setSelectedPortfolio(null);
      setSelectedTabIndex(0);
      fetchPortfolios();
    } catch (error) {
      console.error('Error deleting portfolio:', error);
    }
  };

  const handleAssetEdit = (row) => {
    // Find the selected portfolio
    const portfolio = portfolios.find(portfolio => portfolio._id === selectedPortfolio._id);
    if (!portfolio) return; // If no portfolio is found, do nothing

    // Filter the holdings of the selected portfolio to only include the selected asset
    const filteredHoldings = portfolio.holdings.filter(holding => holding.symbol === row.symbol);

    // Set the selected asset and its filtered holdings
    setSelectedAsset({ ...row, holdings: filteredHoldings });

    // Open the modal
    setShowEditModal(true);
  };

  const columns = [
    {
      field: 'symbol',
      headerName: 'Symbol',
      width: 150,
      renderCell: (params) => (
        <div>
          <RouterLink to={`${baseUrl}/Stock/${params.row.symbol}`} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
            {params.row.symbol}
          </RouterLink>
          <div style={{ fontSize: '0.9em', color: '#888' }}>{params.row.name}</div>
        </div>
      )
    },
    // { field: 'name', headerName: 'Name', width: 200 },
    { field: 'quantity', headerName: 'Postion', width: 100 },
    { field: 'quotePrice', headerName: 'Price', width: 100 },
    { field: 'change', headerName: 'Change', width: 100 },
    { field: 'changesPercentage', headerName: 'Change %', width: 100 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'avgPrice', headerName: 'AVG Price', width: 100 },
    { field: 'totalValue', headerName: 'Value', width: 100 },
    { field: 'totalInvestment', headerName: 'Cost', width: 100 },
    { field: 'PnL', headerName: 'P&L', width: 100 },
    // { field: 'exchange', headerName: 'Exchange', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit transactions">
            <EditIcon onClick={() => handleAssetEdit(params.row)} />
          </Tooltip>
          <Tooltip title="Delete all">
            <DeleteIcon onClick={() => handleAssetDelete(selectedPortfolio._id, params.row._id)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  const aggregatedHoldings = (holdings) => {
    const combinedHoldings = [];
    holdings.forEach((holding) => {
      const existingHolding = combinedHoldings.find(h => h.symbol === holding.symbol);
      if (existingHolding) {
        existingHolding.quantity += holding.quantity;
        existingHolding.totalInvestment += holding.quantity * holding.price;
        existingHolding.avgPrice = existingHolding.totalInvestment / existingHolding.quantity;
        existingHolding.totalValue = existingHolding.quantity * existingHolding.quotePrice;
        existingHolding.PnL = existingHolding.totalValue - existingHolding.totalInvestment;
        existingHolding._id = existingHolding._id !== holding._id ? [existingHolding._id, holding._id] : existingHolding._id;
      } else {
        combinedHoldings.push({
          ...holding,
          totalInvestment: holding.quantity * holding.price,
          avgPrice: holding.price,
          totalValue: holding.quantity * holding.quotePrice,
          PnL: (holding.quantity * holding.quotePrice) - (holding.quantity * holding.price)
        });
      }
    });
    console.log('combinedHoldings ' + JSON.stringify(combinedHoldings))
    return combinedHoldings;
  };

  const calculatePerformers = (holdings) => {
    if (!holdings || holdings.length === 0) {
      setBestPerformer(null);
      setWorstPerformer(null);
      return;
    }

    let bestPerformer = holdings[0];
    let worstPerformer = holdings[0];

    holdings.forEach((holding) => {
      // Ensure holding.PnL is defined and not NaN before comparison
      if (holding.PnL !== undefined && !isNaN(holding.PnL)) {
        if (holding.PnL > (bestPerformer?.PnL || -Infinity)) {
          bestPerformer = holding;
        }
        if (holding.PnL < (worstPerformer?.PnL || Infinity)) {
          worstPerformer = holding;
        }
      }
    });

    setBestPerformer(bestPerformer);
    setWorstPerformer(worstPerformer);
  };

  if (!user) {
    navigate('/login');
    return null;
  }

  let portfolioData = {};
  if (selectedPortfolio) {
    selectedPortfolio.holdings.forEach(holding => {
      const existingQuantity = portfolioData[holding.symbol];
      const newQuantity = holding.quantity;
      portfolioData[holding.symbol] = existingQuantity ? existingQuantity + newQuantity : newQuantity;
    });
  }


  return (
    <Container>
      {portfolios.length > 0 &&
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
          <Grid item xs={12} sm={4} md={4}>
            {/* Total P&L */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
              <Box sx={{ boxShadow: 10, p: 2, textAlign: 'center' }}>
                <Box component="span" sx={{ display: 'block' }}>
                  <strong>Total P&L:</strong>
                </Box>
                <Box component="span" sx={{ display: 'block', fontSize: '2rem', color: (totalValue - totalCost) > 0 ? 'green' : (totalValue - totalCost) < 0 ? 'red' : 'gray' }}>
                  {(totalValue - totalCost).toFixed(2)}$
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: 10, width: '100%', flex: 1, p: 2 }}>
                <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
                  <Box component="span" sx={{ display: 'block' }}>
                    <strong>Total Cost:</strong>
                  </Box>
                  <Box component="span" sx={{ display: 'block', fontSize: '1.5rem' }}>
                    {totalCost.toFixed(2)}$
                  </Box>
                </Box>
                <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
                  <Box component="span" sx={{ display: 'block' }}>
                    <strong>Total Value:</strong>
                  </Box>
                  <Box component="span" sx={{ display: 'block', fontSize: '1.5rem' }}>
                    {totalValue.toFixed(2)}$
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: 10, width: '100%', flex: 1, p: 2 }}>
                <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
                  <Box component="span" sx={{ display: 'block' }}>
                    <strong>
                      {totalValue - totalCost > 0 ? 'Best Performer:' : 'Worst Performer:'}
                    </strong>
                  </Box>
                  <Box component="span" sx={{ color: totalValue - totalCost > 0 ? 'green' : 'red' }}>
                    {totalValue - totalCost > 0 ?
                      bestPerformer ? `${bestPerformer.symbol} (${bestPerformer.PnL.toFixed(2)}$)` : 'N/A'
                      :
                      worstPerformer ? `${worstPerformer.symbol} (${worstPerformer.PnL.toFixed(2)}$)` : 'N/A'
                    }
                  </Box>
                </Box>

                <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
                  <Box component="span" sx={{ display: 'block' }}>
                    <strong>Daily Change:</strong>
                  </Box>
                  <Box component="span" sx={{ display: 'block', fontSize: '1rem', color: dailyChange > 0 ? 'green' : dailyChange < 0 ? 'red' : 'gray' }}>
                    {/* Assuming there's a variable for Worst Performer */}
                    {Math.floor(dailyChange * 100) / 100}$
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Box sx={{ boxShadow: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, height: '100%' }}>
              {selectedPortfolio && <DoughnutChart data={portfolioData} headline="Allocation" />}
            </Box>
          </Grid>
        </Grid>
      }


      <div>
        <Modal open={showAddPortfolioAssetModal} onClose={() => setShowAddPortfolioAssetModal(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box>
            <AddPortfolioAsset
              portfolioId={selectedPortfolio && selectedPortfolio._id}
              handleCloseModalAndRefetchData={handleCloseModalAndRefetchData}
            />
          </Box>
        </Modal>
        <AppBar position="static" sx={{ marginTop: 2 }}>
          <Toolbar>
            <Tabs
              value={selectedTabIndex}
              onChange={handleTabChange}
              aria-label="portfolio tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "& .Mui-selected": {
                  color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                },
              }}
            >
              {portfolios.map((portfolio) => (
                <Tab key={portfolio._id} label={portfolio.name}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                    },
                  }}
                />
              ))}
            </Tabs>
            <Button variant="contained"
              onClick={handleMenuOpen}
              style={{ minWidth: 0, width: 'auto', marginLeft: 'auto' }}>
              <BuildIcon fontSize="small">
                Create / Manage
              </BuildIcon>
            </Button>
            <Menu
              id="portfolio-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >

              {portfolios.length > 0 && (
                <MenuItem onClick={() => setShowAddPortfolioAssetModal(true)}>Add Transaction</MenuItem>
              )}
              {portfolios.length > 0 && (
                <MenuItem onClick={() => setShowDeleteModal('PortfolioDelete')}>Delete portfolio</MenuItem>
              )}
              {showDeleteModal === 'PortfolioDelete' && (
                <DeleteModal
                  open={true}
                  onClose={handleDeleteModalClose}
                  onConfirm={() => handlePortfolioDelete(selectedPortfolio)}
                  itemName={selectedPortfolio.name} // Assuming selectedPortfolio is available
                />
              )}

              <MenuItem onClick={() => setShowCreateModal('PortfolioCreate')}>Create Portfolio</MenuItem>
              {showCreateModal === 'PortfolioCreate' && (
                <CreateModal
                  open={true}
                  onClose={handleCreateModalClose}
                  onCreate={handleCreatePortfolio}
                  title="Create Portfolio"
                />
              )}
            </Menu>
          </Toolbar>
        </AppBar>
      </div>

      {selectedPortfolio && selectedPortfolio.holdings.length > 0 ? (
        <div>
          <DataGrid
            rows={aggregatedHoldings(selectedPortfolio.holdings)}
            getRowId={(row) => (row && row._id) || ''}
            columns={columns}
            disableColumnMenu
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: {
                sortModel: [{ field: 'symbol', sort: 'asc' }],
              },
            }}
            pageSizeOptions={[5, 10, 25]}
          />
        </div>
      ) : (
        <Typography variant="body1">No holdings in the selected portfolio.</Typography>
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />

      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Card sx={{ maxWidth: '500px', mx: 'auto', my: 2 }}>
          <CardContent className="text-center" style={{ textAlign: 'center' }}>
            <h3>Transactions Overview</h3>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Symbol</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedAsset && selectedAsset.holdings.map((holding, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {holding.symbol}
                      </TableCell>
                      <TableCell align="right">{holding.price}</TableCell>
                      <TableCell align="right">{holding.quantity}</TableCell>
                      <TableCell align="right">
                        <DeleteIcon onClick={() => handleAssetDelete(selectedPortfolio._id, holding._id)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Modal>

      <UpgradePopup
        open={showUpgradePopup}
        onClose={handleUpgradePopupClose}
      />

    </Container>
  );


};

export default Portfolio;
