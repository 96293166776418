import React, { useState } from 'react';
import { Chart, ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend, DoughnutController } from 'chart.js';
import { Box, Link } from '@mui/material';
import DoughnutChartComponent from './DoughnutChartComponent'; // Importing the DoughnutChartComponent
import PieChartModal from './PieChartModal'; // Reusing PieChartModal component

// Register chart.js plugins
Chart.register(ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend, DoughnutController);

const DoughnutChart = ({ data, headline }) => {
    const [open, setOpen] = useState(false);
    const [dataArray, setDataArray] = useState([]);

    const handleOpen = () => {
        const total = Object.values(data).reduce((acc, value) => acc + value, 0);
        const dataArray = Object.entries(data).map(([label, value]) => ({
            label,
            value,
            percentage: ((value / total) * 100).toFixed(2)
        })).sort((a, b) => b.percentage - a.percentage);
        setDataArray(dataArray);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    if (!data) {
        return null;
    }

    const colors = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
        '#FF5733', '#00BFFF', '#FFD700', '#32CD32', '#FF1493', '#1E90FF', '#FF4500',
        '#8A2BE2', '#FFA500', '#7CFC00', '#FF6347', '#00FFFF', '#FF69B4',
        '#8BC1F7', '#519DE9', '#06C', '#004B95', '#002F5D',
        '#BDE2B9', '#7CC674', '#4CB140', '#38812F', '#23511E',
        '#A2D9D9', '#73C5C5', '#009596', '#005F60', '#003737',
        '#B2B0EA', '#8481DD', '#5752D1', '#3C3D99', '#2A265F',
        '#F9E0A2', '#F6D173', '#F4C145', '#F0AB00', '#C58C00',
        '#F4B678', '#EF9234', '#EC7A08', '#C46100', '#8F4700',
        '#C9190B', '#A30000', '#7D1007', '#470000', '#2C0000',
        '#F0F0F0', '#D2D2D2', '#B8BBBE', '#8A8D90', '#6A6E73'
    ];

    const labels = Object.keys(data);
    const datasetData = Object.values(data);
    const backgroundColor = labels.map((_, index) => colors[index % colors.length]);

    const chartData = {
        labels: labels,
        datasets: [
            {
                data: datasetData,
                backgroundColor: backgroundColor,
                hoverBackgroundColor: backgroundColor,
                datalabels: {
                    display: false, // Initially hide labels
                },
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 10,
                    padding: 10,
                    generateLabels: function (chart) {
                        const labels = Chart.overrides.doughnut.plugins.legend.labels.generateLabels(chart);
                        return labels.slice(0, 6);
                    }
                },
                onClick: () => false
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || '';
                        const total = tooltipItem.dataset.data.reduce((acc, val) => acc + val, 0);
                        const percentage = ((value / total) * 100).toFixed(2);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                }
            },
            datalabels: {
                display: (context) => {
                    return context.active ? 'auto' : false; // Show labels only when active (hovered)
                },
                formatter: (value, context) => {
                    const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${percentage}%`;
                },
                color: '#fff',
                font: {
                    weight: 'bold',
                }
            }
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };

    return (
        <div>
            {headline && <h4>{headline}</h4>}
            <div>
                <DoughnutChartComponent chartData={chartData} options={options} />
            </div>
            <Box sx={{ textAlign: 'right' }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleOpen}
                    underline="hover"
                >
                    View All
                </Link>
            </Box>
            <PieChartModal open={open} handleClose={handleClose} dataArray={dataArray} headline={headline} />
        </div>
    );
};

export default DoughnutChart;
