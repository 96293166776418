import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, useTheme, Button, Box } from '@mui/material';
import TextCarousel from './TextCarousel';
import backgroundImage from '../images/background.png';

const HeroSection = () => {
  const theme = useTheme();
  const phrases = [
    'Financial Data & Analytics Tools',
    'Market Screeners',
    'Stock Screeners',
    'Stocks Overview',
    'Portfolio Tracking',
    'Watchlist Tracking',
    'Crypto Price Tracking',
  ];

  return (
    <div style={theme.hero}>
      <Container maxWidth="lg" sx={{ mt: 6, mb: 6 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Text and button section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 0} }}>
              <Typography variant="h2" gutterBottom>
                Powerful
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2, mt: 2 }}>
                <TextCarousel phrases={phrases} intervalDuration={3000} />
              </Box>
              <Typography variant="h5" gutterBottom>
                <strong>Transforming Investing with Advanced Stock Screeners and Crypto Analysis.</strong>
              </Typography>
              <Link to="/Signup">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ ...theme.ctaButton, marginTop: 2 }}
                >
                  Get Started
                </Button>
              </Link>
            </Box>
          </Grid>

          {/* Image section */}
          <Grid item xs={12} md={6}>
            <img
              src={backgroundImage}
              alt="Financial data"
              style={{ width: '100%', height: 'auto' }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HeroSection;
